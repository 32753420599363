/*

Form's layout

*/

.form {
    position: relative;
    @include clearfix;
    @include margin-top(calc(60rem/$k));

    &__items {
        position: relative;
        @include clearfix;
        @include margin-top(calc(20rem/$k));
    }

    &__item {
        position: relative;
        @include clearfix;
        @include margin-top(calc(40rem/$k));

        @media (max-width: $tablet) {
            margin-top: calc(30rem/$k);
        }

        &-label {
            display: block;
            font-size: calc(13rem/$k);
            line-height: calc(22/13);
            letter-spacing: 0.05em;
            text-transform: uppercase;
            @include margin-top(calc(6rem/$k));
        }

        &-field {
            position: relative;
            @include margin-top(calc(6rem/$k));
        }

        &--checkboxes {
            margin-top: calc(30rem/$k);
        }
    }

    &__actions {
        position: relative;
        @include margin-top(calc(55rem/$k));

        @media (max-width: $tablet) {
            margin-top: calc(45rem/$k);
        }
    }

    &__error {
        padding: calc(5rem/$k) 0 0;
        font-size: calc(14rem/$k);
        line-height: 1.25;
        color: #f66;
        animation: 0.5s ease-out;
        animation-iteration-count: 2;
        animation-fill-mode: both;
        animation-delay: 0.3s;
        animation-name: pulsateError;

        @media (max-width: $tablet) {
            font-size: calc(12rem/$k);
            line-height: calc(16/12);
        }

        .form__item-field--error-absolute & {
            @include position(absolute, 100% 0 null 0);
            margin-bottom: 0;
        }

        .form__item-field--error-white & {
            color: #fff;
        }

        &.active {
            animation-name: pulsateError;
        }
    }

    &__message {
        display: none;

        &.active {
            display: block;
        }
    }

    &__tip {
        @include margin-top(calc(10rem/$k));
        font-size: calc(12rem/$k);
        line-height: 1.25;
    }
    
    &.inactive {
        
        .form__items {
            display: none;
        }
    }
}

@keyframes pulsateError {

    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1.0;
    }
}


.form-required {
    color: #f5203a;
}