



/*

Loaders

*/


.loader {
    display: inline-block;
    vertical-align: middle;
    @include size(calc(40rem/$k));
    opacity: 0;
    transition: opacity $ts;
    pointer-events: none;
    text-indent: 0;

    .btn &, .loader-set-absolute > & {
        @include position(absolute, 50% null null 50%);
        margin: calc(-20rem/$k) 0 0 calc(-20rem/$k);
    }

    .loading > & {
        opacity: 1;
    }

    &.active {
        opacity: 1;
    }

    * {
        fill: currentColor;

        .btn & {
            fill: currentColor;
        }
    }

    svg {
        width: 100%;
        height: 100%;
    }
}

.btn.loading {
    pointer-events: none;
    background: none!important;
    color: transparent!important;

    &:before {
        opacity: 0!important;
    }
}

.btn__loader-inner {
    transition: opacity $ts;

    .loading > & {
        opacity: 0;
    }
}