/*

Textfields

*/

textarea,
input,
button {
    -webkit-font-smoothing: antialiased;
    outline: none;
    line-height: 1.25;
    font-family: inherit;
}

.textfield {
    position: relative;
    z-index: 0;
    display: inline-block;
    font-family: $font1;
    word-spacing: 0.2em;
    font-size: calc(26rem/$k);
    font-weight: 400;
    line-height: 1.285714285714286;
    background: transparent;
    color: $color3;
    padding: calc(13rem/$k) 0;
    width: 100%;
    border: none;
    box-shadow: inset 0 calc(-1rem/$k) 0 #585154;
    border-radius: 0;
    -webkit-appearance: none!important;
    outline: none;
    resize: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: color $ts, background-color $ts, border-color $ts, box-shadow $ts;

    @media (max-width: $tablet) {
        font-size: calc(22rem/$k);
        padding: calc(10rem/$k) 0;
    }

    @media (max-width: $mobile) {
        font-size: calc(18rem/$k);
    }

    &:hover {
        box-shadow: inset 0 calc(-1rem/$k) 0 $color3;
    }

    &:focus {
        box-shadow: inset 0 calc(-1rem/$k) 0 $color3;
        outline: none;
    }

    &:invalid {
        background: transparent;
    }

    &:-webkit-autofill {
        -webkit-text-fill-color: $color3;
        box-shadow: inset 0 calc(-1rem/$k) 0 #585154, inset 0 0 0 calc(300rem/$k) $color0;
    }

    &.error {
        box-shadow: inset 0 calc(-1rem/$k) 0 #f66, inset 0 0 0 calc(300rem/$k) $color0;
    }

    &::-ms-clear {
        display: none;
        width: 0;
        height: 0;
    }

    @include placeholder {
        color: rgba($color3, 0.85);
        opacity: 1;
    }

    &--w-icon {
        padding-right: calc(64rem/$k);
    }

    &-icon {
        @include position(absolute, 0 0 0 null);
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: calc(64rem/$k);
        font-size: calc(20rem/$k);
    }

    &[disabled],
    &.disabled {
        color: $color0;
        opacity: 0.6;
        border-color: transparent;
        cursor: default;
        cursor: not-allowed;
        z-index: 2;
    }
}


textarea.textfield {
    white-space: pre-wrap;
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
    -webkit-overflow-scrolling: touch;
    //line-height: (28/16);
    //padding-top: 0;
    //padding-bottom: 0;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}