/*

Buttons

*/

.btn {
    position: relative;
    display: inline-block;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    outline: none;
    text-decoration: none;
    text-transform: none;
    text-align: center;
    font-family: $font1;
    word-spacing: 0.2em;
    font-weight: 400;
    font-size: calc(20rem/$k);
    line-height: calc(18/14);
    letter-spacing: 0;
    border: none;
    padding: calc(12rem/$k) calc(35rem/$k);
    min-width: calc(54rem/$k);
    min-height: calc(54rem/$k);
    vertical-align: middle;
    border-radius: 0;
    color: rgba($color3, 0.85) !important;
    -webkit-appearance: none;
    background: $color0;
    user-select: none;
    backface-visibility: hidden;
    box-sizing: border-box;
    transition: color $ts, background-color $ts, box-shadow $ts;
    cursor: pointer;
    z-index: 0;

    @media (max-width: $tablet) {
        font-size: calc(22rem/$k);
        padding: calc(12rem/$k) calc(25rem/$k);
        min-width: calc(48rem/$k);
        min-height: calc(48rem/$k);
    }

    @media (max-width: $mobile) {
        font-size: calc(16rem/$k);
        padding: calc(6rem/$k) calc(12rem/$k);
    }

    &:before {
        @include position(absolute, 0 0 0 0);
        border: 1px solid $color3;
        content: '';
        transition: all $ts;
    }

    &::-moz-focus-inner {
        padding: 0 !important;
        border: 0 none !important;
    }

    &:hover {
        text-decoration: none;
        color: $color0!important;
        background-color: $color3;
    }

    &.active,
    &:active {
        text-decoration: none;
        color: $color0!important;
        background-color: $color3;
    }

    &[disabled],
    &.disabled {
        background: $color0;
        color: rgba($color3, 0.85)!important;
        cursor: default;
        cursor: not-allowed;
        opacity: 0.5;
    }

    & + .btn {
        margin-left: calc(20rem/$k);
    }

    & > input[type='file'] {
        opacity: 0;
        font-size: calc(120rem/$k);
        position: absolute;
        top: calc(-20rem/$k);
        right: calc(-10rem/$k);
        vertical-align: top;
        z-index: 3;
        cursor: pointer;
    }

    .icon {
        font-size: calc(65rem/$k);
        margin: calc(-5rem/$k) calc(30rem/$k);

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &--trigger-parent {

        &:after {
            @include position(absolute, calc(-500rem/$k) calc(-500rem/$k) calc(-500rem/$k) calc(-500rem/$k));
            content: '';
        }
    }

    &--fullwidth {
        width: 100%;
        @include margin-top(calc(20rem/$k));
    }

}