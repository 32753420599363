/*

Column Layout

*/


.cols {
    display: table;
    width: 100%;
    table-layout: fixed;

    &--auto {
        table-layout: auto;
    }

    &--fullheight {
        height: 100%;
    }

    &__inner {
        display: table-row;
    }
}

.col {
    display: table-cell;
    vertical-align: top;
    padding-left: calc(15rem/$k);
    padding-right: calc(15rem/$k);

    &--expander {
        padding: 0!important;
    }
}

.cols-row {
    display: table-row;
}

.cols-wrapper {
    @include margin-top(calc(30rem/$k));
    margin-left: calc(-15rem/$k);
    margin-right: calc(-15rem/$k);
    @include clearfix;

    &--no-padding {
        margin-left: 0;
        margin-right: 0;

        > .cols {

            > .col {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &--mobile-plain {

        > .cols {

            @media (max-width: $mobile) {
                display: block;
                margin-bottom: calc(-30rem/$k);
            }

            > .col {

                @media (max-width: $mobile) {
                    display: block;
                    width: auto;
                    margin-bottom: calc(30rem/$k);
                }
            }
        }

        &.cols-wrapper--form {

            > .cols {

                @media (max-width: $mobile) {
                    margin-bottom: calc(-50rem/$k);
                }

                > .col {

                    @media (max-width: $mobile) {
                        margin-bottom: calc(50rem/$k);
                    }
                }
            }
        }
    }
}

.cols-wrapper--form {
    margin-left: calc(-25rem/$k);
    margin-right: calc(-25rem/$k);

    > .cols {

        > .col {
            padding-left: calc(25rem/$k);
            padding-right: calc(25rem/$k);
        }
    }
}

.cols--reverse {
    direction: rtl;

    > .col {
        direction: ltr;
    }
}

.col--fit {
    width: 1px;
}

$columns: 12;

@for $i from 1 through $columns {

    .col--#{$i}-12 {
        width: calc($i/$columns*100%);
    }
}

.col--1-4 {
    width: 25%;
}

.col--3-4 {
    width: 75%;
}

.col--1-3 {
    width: 33.33%;
}

.col--2-3 {
    width: 66.66%;
}

.col--1-2 {
    width: 50%;
}

.cols--flex {
    display: flex;
    width: auto;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    > .col {
        display: block;

        @media (max-width: $mobile) {
            &.col--2-3 {
                width: 100%;
            }

            &.col--1-3 {
                width: 100%;
            }

            &.col--1-2 {
                width: 100%;
            }
        }

        @media (max-width: $tablet) {
            &.col--1-4 {
                width: 100%;
            }

            &.col--3-4 {
                width: 100%;
            }
        }
       
    }
}