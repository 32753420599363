
/*
Лейаут шапки
*/

.header {
    @include position(relative, 0 0 null 0);
    z-index: 40;
    line-height: 1.2;
    backface-visibility: hidden;
    will-change: transform;
    transition: all 0.4s;

    &--fixed {
        position: fixed;

        &.header--hidden {
            transform: translateY(-100%);
        }

        &.header--scrolled {
            //background-color: rgba(#ffd5ce, 0.93);
            background-color: hsla(9,87%,96%,0.95);
        }
    }

    &-replace {
        height: calc(210rem/$k);

        @media (max-width: $screen_xxlarge) {
            height: calc(160rem/$k);
        }

        @media (max-width: $tablet) {
            height: calc(120rem/$k);
        }

        @media (max-width: $mobile) {
            height: calc(80rem/$k);
        }

        .header + & {
            display: none;
        }

        .header--fixed + & {
            display: block;
        }

        .header--over + & {
            display: none;
        }
    }

    .col {
        transition: height 0.4s;

        &--cbutton {
            width: 1%;
        }

        &--logo {
            width: 1%;
        }
    }

    &__inner {
        position: relative;
        z-index: 2;
    }

    .col {
        height: calc(210rem/$k);
        transition: height 0.4s;

        @at-root {

            .header--scrolled#{&} {

                @media (min-width: $tablet+1px) {
                    height: calc(96rem/$k);
                }
            }
        }

        @media (max-width: $screen_xxlarge) {
            height: calc(160rem/$k);
        }

        @media (max-width: $tablet) {
            height: calc(120rem/$k);
        }

        @media (max-width: $mobile) {
            height: calc(64rem/$k);
        }
    }

    .hmenu {
        top: calc(7rem/$k);
        transition: top 0.4s;

        @at-root {

            .header--scrolled#{&} {
                top: 0;
            }
        }
    }
}

.header--scrolled .icon--logo {
    width: calc(156em/67);
}

.cols-wrapper--header {

    > .cols {
        table-layout: auto;

        > .col {
            position: relative;
            vertical-align: middle;
            line-height: 1;
            transition: height $ts;

            &--fit {
                width: 1px;
            }

            &--cbutton {
            }
        }
    }
}



/*
Лого
*/

.logo {
    display: inline-block;
    text-decoration: none!important;
    transition: all $ts;
    white-space: nowrap;
    font-family: $font2;
    font-size: calc(28rem/$k);
    font-weight: 700;
    line-height: 0.97;
    word-spacing: 0.2em;
    text-transform: uppercase;


    @media (max-width: $tablet) {
        font-size: calc(18rem/$k);
    }

    .icon {
        vertical-align: top;
    }

    &[href^='#'] {

        .header & {
            pointer-events: none;
        }

        .header--scrolled & {
            pointer-events: auto;
        }
    }
}


.hmenu {
    position: relative;
    @include margin-top(calc(10rem/$k));

    &__list {
        display: inline-block;
        vertical-align: top;
        min-width: 100%;
        font-size: 0;
        margin-left: calc(-75rem/$k);
        margin-bottom: calc(-15rem/$k);

        @media (max-width: $tablet) {
            margin-left: calc(-60rem/$k);
        }
    }

    &__item {
        position: relative;
        display: inline-block;
        vertical-align: top;
        font-size: calc(18rem/$k);
        font-weight: 500;
        line-height: calc(22/18);
        margin-left: calc(75rem/$k);
        margin-bottom: calc(15rem/$k);

        @media (max-width: $tablet) {
            margin-left: calc(60rem/$k);
        }

        &.active {

            > .hmenu__link {

                &:after {
                    opacity: 1;
                }
            }
        }
    }

    &__link {
        position: relative;
        padding: calc(2rem/$k) 0;
        color: inherit;
        text-decoration: none!important;
        transition: all $ts;

        &:before {
            @include position(absolute, null 0 0 0);
            border-bottom: 1px solid;
            border-color: currentColor;
            content: '';
            transform-origin: 0 50%;
            transform: scaleX(0);
            transition: all $ts;
        }

        &:after {
            @include position(absolute, calc(-15rem/$k) calc(-30rem/$k) calc(-15rem/$k) calc(-30rem/$k));
            border-radius: calc(100rem/$k);
            background-color: rgba($color2, 0.15);
            opacity: 0;
            transform: translateY(calc(1rem/$k));
            content: '';
            transition: all $ts;

            @media (max-width: $tablet) {
                @include position(null, calc(-10rem/$k) calc(-20rem/$k) calc(-10rem/$k) calc(-20rem/$k));
            }
        }

        &:hover {

            &:before {
                transform: scaleX(1);
            }
        }
    }
}



/*
Кнопка-гамбургер открытия меню для мобильных
*/

.cbutton {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    @include size(calc(32rem/$k), calc(20rem/$k));
    text-align: center;
    font-family: Arial, sans-serif;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    z-index: 5;
    transition: all $ts;

    &:hover {
        text-decoration: none;
    }

    &:active {
        text-decoration: none;
    }

    &:before {
        @include position(absolute, calc(-10rem/$k) calc(-10rem/$k) calc(-10rem/$k) calc(-10rem/$k));
        content: '';
        z-index: 1;
    }

    &__inner {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        line-height: 1;
    }

    &__item {
        position: relative;
        display: block;
        @include size(calc(32rem/$k), calc(2rem/$k));
        background: currentColor;
        @include margin-top(calc(7rem/$k));
        transition: transform 0.5s, opacity $ts;

        .menu-overlay-active & {
            opacity: 0;
            transform: translateY(calc(-9rem/$k)) rotate(-45deg);
        }

        &:first-child {

            .menu-overlay-active & {
                opacity: 1;
                transform: translateY(calc(9rem/$k)) rotate(135deg);
            }
        }

        &:first-child + * {

            .menu-overlay-active & {
                opacity: 1;
                transform: rotate(45deg);
            }
        }
    }
}



.menu-overlay {
    @include position(absolute, 0 0 null 0);
    background-color: $color0;
    transform: translate3d(0, -100%, 0);
    z-index: -1;
    visibility: hidden;
    transition: transform 0.5s, opacity 0.5s, visibility 0s 0.5s, z-index 0s 0.5s;

    .menu-overlay-active & {
        z-index: 0;
        transform: none;
        visibility: visible;
        transition-delay: 0s;
    }

    &__content {
        max-height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        padding: calc(180rem/$k) 0 calc(100rem/$k);
        transition: all 0.4s;

        @media (max-height: calc(850rem/$k)) {
            padding: calc(150rem/$k) 0 calc(75rem/$k);
        }
    }

    .hmenu {
        @include margin-top(calc(40rem/$k));
        text-align: center;

        &__list {
            margin-bottom: calc(-40rem/$k);

            @media (max-height: $mobile) {
                margin-bottom: calc(-30rem/$k);
            }
        }

        &__item {
            display: block;
            font-size: calc(18rem/$k);
            line-height: calc(32/18);
            margin-bottom: calc(40rem/$k);

            @media (max-height: $mobile) {
                margin-bottom: calc(30rem/$k);
            }
        }

        &__link {
            border-bottom: none;
        }
    }
}

.menu-overlay-bg {
    @include position(fixed, 0 0 0 0);
    opacity: 0;
    visibility: hidden;
    z-index: -2;
    background: $color0;
    transition: opacity 0.5s 0.15s, visibility 0s 0.6s, z-index 0s 0.6s;

    .menu-overlay-active & {
        opacity: 1;
        visibility: visible;
        z-index: 35;
        transition-delay: 0s;
    }
}