
/*

Common property classes

 */


.relative {
    position: relative;
}

.static {
    position: static;
}

.absolute {
    position: absolute;
}

.inline {
    display: inline;
}

.inline-block {
    display: inline-block;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-visible {
    overflow: visible;
}

.reset-margin {
    margin: 0;
}

.margin-top-auto {
    margin-top: auto;
}

.margin-auto {
    margin-left: auto;
    margin-right: auto;
}

.hidden-absolute {
    position: absolute;
    top: calc(-9999rem/$k);
    left: calc(-9999rem/$k);
}

.text-align-left {
    text-align: left;
}

.text-align-right {
    text-align: right;
}

.text-align-center {
    text-align: center;
}

.text-align-left {

    &-on-mobile {

        @media (max-width: $mobile) {
            text-align: left;
        }
    }
}

.text-align-right {

    &-on-mobile {

        @media (max-width: $mobile) {
            text-align: right;
        }
    }
}


.text-align-center {

    &-on-mobile {

        @media (max-width: $mobile) {
            text-align: center;
        }
    }
}

.text-ellipsis {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.vmiddle {
    vertical-align: middle;
}

.vbottom {
    vertical-align: bottom;
}

.vbaseline {
    vertical-align: baseline;
}

.clear {
    clear: both;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

.nowrap {
    white-space: nowrap;
}

.wrap-normal {
    white-space: normal;
}

.no-text-transform {
    text-transform: none;
}

.uppercase {
    text-transform: uppercase;
}

.underline {
    text-decoration: underline;
}

.nounderline {
    text-decoration: none;
}

.nounderline-important {
    text-decoration: none!important;
}

.underline-hover:hover {
    text-decoration: underline;
}

.underline-hover:active {
    text-decoration: underline;
}

.line-through {
    text-decoration: line-through;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.pointer-events-none {
    pointer-events: none;
}

.pointer-events-auto {
    pointer-events: auto;
}

.color-white {
    color: #fff;
}

.bg-color-white {
    background-color: #fff;
}

.color-inherit {
    color: inherit;
}

.color-inherit-important {
    color: inherit!important;
}

.color-default {
    color: $color0;
}

.color-muted {
    color: #bebebe;
}

.color-active {
    color: $color1;
}

.color-active2 {
    color: $color2;
}

.color-active4 {
    color: $color4;
}

.color-active5 {
    color: $color5;
}

.lh-default {
    line-height: 1.25;
}

.lh-default-more {
    line-height: 1.4;
}

.font-monospace {
    font-family: Consolas, Monaco, Menlo, 'Courier New', Courier, monospace;
}

.font-active2 {
    font-family: $font2;
}

.font-0 {
    font-size: 0;
}

.font-xsmall {
    font-size: calc(10rem/$k);
}

.font-small {
    font-size: calc(12rem/$k);
}

.font-smaller {
    font-size: calc(13rem/$k);
}

.font-normal {
    font-size: calc(14rem/$k);
}

.font-larger {
    font-size: calc(15rem/$k);
}

.font-large {
    font-size: calc(16rem/$k);
}

.font-18 {
    font-size: calc(18rem/$k);
}

.font-20 {
    font-size: calc(20rem/$k);
}

.font-normal-tablet {

    @media (max-width: $tablet) {
        font-size: calc(14rem/$k);
    }
}

.font-thin {
    font-weight: 200;
}

.font-light {
    font-weight: 300;
}

.font-regular {
    font-weight: 400;
}

.font-medium {
    font-weight: 500;
}

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.font-extrabold {
    font-weight: 800;
}

.font-black {
    font-weight: 900;
}



.minw140 {
    min-width: calc(140rem/$k);
}

.minw160 {
    min-width: calc(160rem/$k);
}

.mw200 {
    max-width: calc(200rem/$k);
}

.mw250 {
    max-width: calc(250rem/$k);
}

.mw300 {
    max-width: calc(300rem/$k);
}

.mw350 {
    max-width: calc(350rem/$k);
}

.mw400 {
    max-width: calc(400rem/$k);
}

.mw480 {
    max-width: calc(480rem/$k);
}

.mw540 {
    max-width: calc(540rem/$k);
}

.mw560 {
    max-width: calc(560rem/$k);
}

.mw640 {
    max-width: calc(640rem/$k);
}

.mw720 {
    max-width: calc(720rem/$k);
}

.mw1600 {
    max-width: calc(1600rem/$k);
}

.mw100percent {
    max-width: 100%;
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.mb-0 {
    margin-bottom: 0;
}