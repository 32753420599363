@charset "UTF-8";
@font-face {
  font-family: "Lato";
  src: url("../../static/font/subset-Lato-Regular.woff2") format("woff2"), url("../../static/font/subset-Lato-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
}
@font-face {
  font-family: "Lato";
  src: url("../../static/font/subset-Lato-Medium.woff2") format("woff2"), url("../../static/font/subset-Lato-Medium.woff") format("woff");
  font-style: normal;
  font-weight: 500;
  font-display: fallback;
}
@font-face {
  font-family: "Lato";
  src: url("../../static/font/subset-Lato-Bold.woff2") format("woff2"), url("../../static/font/subset-Lato-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
  font-display: fallback;
}
@font-face {
  font-family: "Goldman";
  src: url("../../static/font/goldman-v1-latin-regular.woff2") format("woff2"), url("../../static/font/goldman-v1-latin-regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: fallback;
}
a, .link {
  outline: none;
  color: inherit;
  text-decoration: underline;
  background-color: transparent;
  transition: color 0.3s, border-color 0.3s;
}
a:hover, .link:hover {
  text-decoration: underline;
}
a:active, .link:active {
  text-decoration: underline;
}

sub, sup {
  font-size: 65%;
}

img {
  max-width: 100%;
}
img.fullwidth {
  display: block;
  width: 100%;
  height: auto;
}
p img.b-lazy {
  opacity: 0;
  transition: opacity 0.3s;
}
p img.b-loaded {
  opacity: 1;
}

strong {
  font-weight: 700;
}

h1, .h1 {
  color: rgb(245, 70, 84);
  position: relative;
  font-family: "Goldman", Arial, sans-serif;
  font-size: 6.25rem;
  font-weight: 700;
  line-height: 0.97;
  word-spacing: 0.2em;
  text-transform: uppercase;
  margin: 11.25rem 0 0;
}
@media (max-width: 1023px) {
  h1, .h1 {
    font-size: 3.125rem;
  }
}
@media (max-width: 767px) {
  h1, .h1 {
    font-size: 4.0625rem;
    text-transform: none;
  }
}
h1:first-child, .h1:first-child {
  margin-top: 0;
}
h1.pd400, .h1.pd400 {
  padding-right: 400px;
}
@media (max-width: 1023px) {
  h1.pd400, .h1.pd400 {
    padding-right: 0px;
  }
}
h1.pd100, .h1.pd100 {
  padding-right: 100px;
}
@media (max-width: 1023px) {
  h1.pd100, .h1.pd100 {
    padding-right: 0px;
  }
}
h1.pd600, .h1.pd600 {
  padding-right: 600px;
}
@media (max-width: 1023px) {
  h1.pd600, .h1.pd600 {
    padding-right: 0px;
  }
}
h1.pd700, .h1.pd700 {
  padding-right: 700px;
}
@media (max-width: 1023px) {
  h1.pd700, .h1.pd700 {
    padding-right: 0px;
  }
}
h1.thin, .h1.thin {
  font-size: 3.125rem;
}
@media (max-width: 1023px) {
  h1.thin, .h1.thin {
    font-size: 4.0625rem;
  }
}
@media (max-width: 767px) {
  h1.thin, .h1.thin {
    font-size: 1.625rem;
    text-transform: none;
  }
}

h2, .h2 {
  font-family: "Lato", Arial, sans-serif;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.2083333333;
  word-spacing: 0.2em;
  margin: 5rem 0 0;
}
@media (max-width: 1023px) {
  h2, .h2 {
    font-size: 2.25rem;
    margin-top: 5.625rem;
  }
}
@media (max-width: 767px) {
  h2, .h2 {
    font-size: 1.5rem;
    margin-top: 3.125rem;
  }
}
h2:first-child, .h2:first-child {
  margin-top: 0;
}
h2 + p, h2 + .p, .h2 + p, .h2 + .p {
  margin-top: 1.4375rem;
}
@media (max-width: 767px) {
  h2 + p, h2 + .p, .h2 + p, .h2 + .p {
    margin-top: 1.875rem;
  }
}
h2.pd600, .h2.pd600 {
  padding-right: 600px;
}
@media (max-width: 1023px) {
  h2.pd600, .h2.pd600 {
    padding-right: 0px;
  }
}
h2.pd400, .h2.pd400 {
  padding-right: 400px;
}
@media (max-width: 1023px) {
  h2.pd400, .h2.pd400 {
    padding-right: 0px;
  }
}
h2.mrgn0, .h2.mrgn0 {
  margin: 0px;
}

h3, .h3 {
  font-family: "Lato", Arial, sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.1666666667;
  margin: 2.5rem 0 0;
}
@media (max-width: 1023px) {
  h3, .h3 {
    font-size: 1.875rem;
  }
}
@media (max-width: 767px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}
h3:first-child, .h3:first-child {
  margin-top: 0;
}

h4, .h4 {
  font-family: "Lato", Arial, sans-serif;
  font-size: 1.875rem;
  font-weight: 400;
  line-height: 1;
  margin: 3.4375rem 0 0;
}
@media (max-width: 1023px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}
@media (max-width: 767px) {
  h4, .h4 {
    font-size: 1.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
h4:first-child, .h4:first-child {
  margin-top: 0;
}
h4.work-title, .h4.work-title {
  text-align: center;
  margin-bottom: 40px;
}

h5, .h5 {
  font-family: "Lato", Arial, sans-serif;
  font-size: 1.875rem;
  font-weight: 200;
  line-height: 1.5;
  margin: 1.875rem 0 0;
}
h5:first-child, .h5:first-child {
  margin-top: 0;
}

p, .p {
  margin: 1.25rem 0 0;
  -webkit-text-size-adjust: 100%;
}
p:first-child, .p:first-child {
  margin-top: 0;
}

ol, ul {
  margin: 1.25rem 0 0;
  padding: 0;
}
ol:first-child, ul:first-child {
  margin-top: 0;
}
ol li, ul li {
  margin: 0 0 0;
  padding: 0 0 0 1.875rem;
}
ol li:first-child, ul li:first-child {
  margin-top: 0;
}

li ul, li ol {
  margin-top: 0.4375rem;
}
li li {
  margin-top: 0.4375rem;
}

ul {
  list-style: none;
}
ul li {
  position: relative;
  padding-left: 1.875rem;
  text-indent: -1.875rem;
  -o-column-break-inside: avoid;
  -ms-column-break-inside: avoid;
  column-break-inside: avoid;
  break-inside: avoid-column;
}
ul li * {
  text-indent: 0;
}
ul li:before {
  position: relative;
  display: inline-block;
  width: 2em;
  text-align: left;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0;
  content: "―";
}

ol {
  counter-reset: ol_counter;
}
ol li {
  text-indent: -1.875rem;
  list-style: none;
  padding: 0 0 0 2.8125rem;
}
ol li:before {
  display: inline-block;
  text-indent: 0;
  height: 1.25rem;
  width: 1.25rem;
  line-height: 1.375rem;
  margin-right: 0.625rem;
  text-align: center;
  background-color: #c2c2c2;
  border-radius: 50%;
  color: #fff;
  counter-increment: ol_counter;
  content: counter(ol_counter);
}

nav ul, .nav ul {
  margin: 0;
  padding: 0;
}
nav li, .nav li {
  margin: 0;
  padding: 0;
  text-indent: 0;
}
nav li:before, .nav li:before {
  display: none;
}

table th, table td {
  padding: 0.1875rem 0.625rem;
}

.hr, hr {
  margin: 4.375rem 0 3.125rem;
  width: 100%;
  padding: 0;
  border: none;
  border-top: 1px solid #585154;
}
@media (max-width: 1023px) {
  .hr, hr {
    margin: 2.5rem 0 1.875rem;
  }
}
.hr:first-child, hr:first-child {
  margin-top: 0;
}
.hr:last-child, hr:last-child {
  margin-bottom: 0;
}
.hr--nomargin, hr--nomargin {
  margin: 0;
}
.hr--bolder, hr--bolder {
  border-top-width: 0.1875rem;
}
h1 + .hr, .h1 + .hr, h1 + hr, .h1 + hr {
  margin-top: 2.1875rem;
}
@media (max-width: 1023px) {
  h1 + .hr, .h1 + .hr, h1 + hr, .h1 + hr {
    margin-top: 1.25rem;
  }
}

.only-for-notouch,
.only-for-touch {
  display: none !important;
}

.only-for-notouch-inline-block,
.only-for-touch-inline-block {
  display: none !important;
}

.only-for-notouch-inline,
.only-for-touch-inline {
  display: none !important;
}

.only-for-notouch-table,
.only-for-touch-table {
  display: none !important;
}

.only-for-notouch-table-row,
.only-for-touch-table-row {
  display: none !important;
}

.only-for-notouch-table-cell,
.only-for-touch-table-cell {
  display: none !important;
}

.only-for-notouch-flex,
.only-for-touch-flex {
  display: none !important;
}

.only-for-notouch-inline-flex,
.only-for-touch-inline-flex {
  display: none !important;
}

.no-touchevents .only-for-notouch,
.touchevents .only-for-touch {
  display: block !important;
}

.no-touchevents .only-for-notouch-inline-block,
.touchevents .only-for-touch-inline-block {
  display: inline-block !important;
}

.no-touchevents .only-for-notouch-inline,
.touchevents .only-for-touch-inline {
  display: inline !important;
}

.no-touchevents .only-for-notouch-table,
.touchevents .only-for-touch-table {
  display: table !important;
}

.no-touchevents .only-for-notouch-table-row,
.touchevents .only-for-touch-table-row {
  display: table-row !important;
}

.no-touchevents .only-for-notouch-table-cell,
.touchevents .only-for-touch-table-cell {
  display: table-cell !important;
}

.no-touchevents .only-for-notouch-flex,
.touchevents .only-for-touch-flex {
  display: flex !important;
}

.no-touchevents .only-for-notouch-inline-flex,
.touchevents .only-for-touch-inline-flex {
  display: inline-flex !important;
}

.screen-xl-show {
  display: none !important;
}

.screen-xl-show-inline-block {
  display: none !important;
}

.screen-xl-show-inline {
  display: none !important;
}

.screen-xl-show-table {
  display: none !important;
}

.screen-xl-show-table-row {
  display: none !important;
}

.screen-xl-show-table-cell {
  display: none !important;
}

.screen-xl-show-flex {
  display: none !important;
}

.screen-xl-show-inline-flex {
  display: none !important;
}

.tablet-show {
  display: none !important;
}

.tablet-show-inline-block {
  display: none !important;
}

.tablet-show-inline {
  display: none !important;
}

.tablet-show-table {
  display: none !important;
}

.tablet-show-table-row {
  display: none !important;
}

.tablet-show-table-cell {
  display: none !important;
}

.tablet-show-flex {
  display: none !important;
}

.tablet-show-inline-flex {
  display: none !important;
}

.tablet-small-show {
  display: none !important;
}

.tablet-small-show-inline-block {
  display: none !important;
}

.tablet-small-show-inline {
  display: none !important;
}

.tablet-small-show-table {
  display: none !important;
}

.tablet-small-show-table-row {
  display: none !important;
}

.tablet-small-show-table-cell {
  display: none !important;
}

.tablet-small-show-flex {
  display: none !important;
}

.tablet-small-show-inline-flex {
  display: none !important;
}

.mobile-show {
  display: none !important;
}

.mobile-show-inline-block {
  display: none !important;
}

.mobile-show-inline {
  display: none !important;
}

.mobile-show-table {
  display: none !important;
}

.mobile-show-table-row {
  display: none !important;
}

.mobile-show-table-cell {
  display: none !important;
}

.mobile-show-flex {
  display: none !important;
}

.mobile-show-inline-flex {
  display: none !important;
}

.mobile-small-show {
  display: none !important;
}

.mobile-small-show-inline-block {
  display: none !important;
}

.mobile-small-show-inline {
  display: none !important;
}

.mobile-small-show-table {
  display: none !important;
}

.mobile-small-show-table-row {
  display: none !important;
}

.mobile-small-show-table-cell {
  display: none !important;
}

.mobile-small-show-flex {
  display: none !important;
}

.mobile-small-show-inline-flex {
  display: none !important;
}

.mobile-xsmall-show {
  display: none !important;
}

.mobile-xsmall-show-inline-block {
  display: none !important;
}

.mobile-xsmall-show-inline {
  display: none !important;
}

.mobile-xsmall-show-table {
  display: none !important;
}

.mobile-xsmall-show-table-row {
  display: none !important;
}

.mobile-xsmall-show-table-cell {
  display: none !important;
}

.mobile-xsmall-show-flex {
  display: none !important;
}

.mobile-xsmall-show-inline-flex {
  display: none !important;
}

.mobile-xxsmall-show {
  display: none !important;
}

.mobile-xxsmall-show-inline-block {
  display: none !important;
}

.mobile-xxsmall-show-inline {
  display: none !important;
}

.mobile-xxsmall-show-table {
  display: none !important;
}

.mobile-xxsmall-show-table-row {
  display: none !important;
}

.mobile-xxsmall-show-table-cell {
  display: none !important;
}

.mobile-xxsmall-show-flex {
  display: none !important;
}

.mobile-xxsmall-show-inline-flex {
  display: none !important;
}

.mobile-xxxsmall-show {
  display: none !important;
}

.mobile-xxxsmall-show-inline-block {
  display: none !important;
}

.mobile-xxxsmall-show-inline {
  display: none !important;
}

.mobile-xxxsmall-show-table {
  display: none !important;
}

.mobile-xxxsmall-show-table-row {
  display: none !important;
}

.mobile-xxxsmall-show-table-cell {
  display: none !important;
}

.mobile-xxxsmall-show-flex {
  display: none !important;
}

.mobile-xxxsmall-show-inline-flex {
  display: none !important;
}

.initial-hide {
  display: none !important;
}

.initial-show {
  display: block !important;
}

.initial-show-inline-block {
  display: inline-block !important;
}

.initial-show-inline {
  display: inline !important;
}

.initial-show-table {
  display: table !important;
}

.initial-show-table-row {
  display: table-row !important;
}

.initial-show-table-cell {
  display: table-cell !important;
}

.initial-show-flex {
  display: flex !important;
}

.initial-show-inline-flex {
  display: inline-flex !important;
}

@media (max-width: 1350px) {
  .screen-xl-hide {
    display: none !important;
  }
  .screen-xl-show {
    display: block !important;
  }
  .screen-xl-show-inline-block {
    display: inline-block !important;
  }
  .screen-xl-show-inline {
    display: inline !important;
  }
  .screen-xl-show-table {
    display: table !important;
  }
  .screen-xl-show-table-row {
    display: table-row !important;
  }
  .screen-xl-show-table-cell {
    display: table-cell !important;
  }
  .screen-xl-show-flex {
    display: flex !important;
  }
  .screen-xl-show-inline-flex {
    display: inline-flex !important;
  }
}
@media (max-width: 1259px) {
  .tablet-hide {
    display: none !important;
  }
  .tablet-show {
    display: block !important;
  }
  .tablet-show-inline-block {
    display: inline-block !important;
  }
  .tablet-show-inline {
    display: inline !important;
  }
  .tablet-show-table {
    display: table !important;
  }
  .tablet-show-table-row {
    display: table-row !important;
  }
  .tablet-show-table-cell {
    display: table-cell !important;
  }
  .tablet-show-flex {
    display: flex !important;
  }
  .tablet-show-inline-flex {
    display: inline-flex !important;
  }
}
@media (max-width: 1023px) {
  .tablet-small-hide {
    display: none !important;
  }
  .tablet-small-show {
    display: block !important;
  }
  .tablet-small-show-inline-block {
    display: inline-block !important;
  }
  .tablet-small-show-inline {
    display: inline !important;
  }
  .tablet-small-show-table {
    display: table !important;
  }
  .tablet-small-show-table-row {
    display: table-row !important;
  }
  .tablet-small-show-table-cell {
    display: table-cell !important;
  }
  .tablet-small-show-flex {
    display: flex !important;
  }
  .tablet-small-show-inline-flex {
    display: inline-flex !important;
  }
}
@media (max-width: 767px) {
  .mobile-hide {
    display: none !important;
  }
  .mobile-show {
    display: block !important;
  }
  .mobile-show-inline-block {
    display: inline-block !important;
  }
  .mobile-show-inline {
    display: inline !important;
  }
  .mobile-show-table {
    display: table !important;
  }
  .mobile-show-table-row {
    display: table-row !important;
  }
  .mobile-show-table-cell {
    display: table-cell !important;
  }
  .mobile-show-flex {
    display: flex !important;
  }
  .mobile-show-inline-flex {
    display: inline-flex !important;
  }
}
@media (max-width: 479px) {
  .mobile-small-hide {
    display: none !important;
  }
  .mobile-small-show {
    display: block !important;
  }
  .mobile-small-show-inline-block {
    display: inline-block !important;
  }
  .mobile-small-show-inline {
    display: inline !important;
  }
  .mobile-small-show-table {
    display: table !important;
  }
  .mobile-small-show-table-row {
    display: table-row !important;
  }
  .mobile-small-show-table-cell {
    display: table-cell !important;
  }
  .mobile-small-show-flex {
    display: flex !important;
  }
  .mobile-small-show-inline-flex {
    display: inline-flex !important;
  }
}
@media (max-width: 413px) {
  .mobile-xsmall-hide {
    display: none !important;
  }
  .mobile-xsmall-show {
    display: block !important;
  }
  .mobile-xsmall-show-inline-block {
    display: inline-block !important;
  }
  .mobile-xsmall-show-inline {
    display: inline !important;
  }
  .mobile-xsmall-show-table {
    display: table !important;
  }
  .mobile-xsmall-show-table-row {
    display: table-row !important;
  }
  .mobile-xsmall-show-table-cell {
    display: table-cell !important;
  }
  .mobile-xsmall-show-flex {
    display: flex !important;
  }
  .mobile-xsmall-show-inline-flex {
    display: inline-flex !important;
  }
}
@media (max-width: 374px) {
  .mobile-xxsmall-hide {
    display: none !important;
  }
  .mobile-xxsmall-show {
    display: block !important;
  }
  .mobile-xxsmall-show-inline-block {
    display: inline-block !important;
  }
  .mobile-xxsmall-show-inline {
    display: inline !important;
  }
  .mobile-xxsmall-show-table {
    display: table !important;
  }
  .mobile-xxsmall-show-table-row {
    display: table-row !important;
  }
  .mobile-xxsmall-show-table-cell {
    display: table-cell !important;
  }
  .mobile-xxsmall-show-flex {
    display: flex !important;
  }
  .mobile-xxsmall-show-inline-flex {
    display: inline-flex !important;
  }
}
@media (max-width: 359px) {
  .mobile-xxxsmall-hide {
    display: none !important;
  }
  .mobile-xxxsmall-show {
    display: block !important;
  }
  .mobile-xxxsmall-show-inline-block {
    display: inline-block !important;
  }
  .mobile-xxxsmall-show-inline {
    display: inline !important;
  }
  .mobile-xxxsmall-show-table {
    display: table !important;
  }
  .mobile-xxxsmall-show-table-row {
    display: table-row !important;
  }
  .mobile-xxxsmall-show-table-cell {
    display: table-cell !important;
  }
  .mobile-xxxsmall-show-flex {
    display: flex !important;
  }
  .mobile-xxxsmall-show-inline-flex {
    display: inline-flex !important;
  }
}
/*

Icons

*/
.icon {
  position: relative;
  display: inline-block;
  fill: currentColor;
  width: 1em;
  height: 1em;
  letter-spacing: 0;
  line-height: 1;
}
.icon--default {
  top: -0.0625rem;
  vertical-align: middle;
  font-size: 1rem;
  margin-left: 0.4375rem;
  margin-right: 0.4375rem;
}
.icon--default:first-child {
  margin-left: 0;
}
.icon--default:last-child {
  margin-right: 0;
}
.icon--16 {
  font-size: 1rem;
}
.icon--20 {
  font-size: 1.25rem;
}
.icon--24 {
  font-size: 1.5rem;
}
.icon--32 {
  font-size: 4.0625rem;
}
.icon--48 {
  font-size: 3rem;
}

.icon--logo {
  width: 2.9253731343em;
  transition: width 0.25s ease-in-out;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

/*

Column Layout

*/
.cols {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.cols--auto {
  table-layout: auto;
}
.cols--fullheight {
  height: 100%;
}
.cols__inner {
  display: table-row;
}

.col {
  display: table-cell;
  vertical-align: top;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}
.col--expander {
  padding: 0 !important;
}

.cols-row {
  display: table-row;
}

.cols-wrapper {
  margin-top: 1.875rem;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
}
.cols-wrapper:first-child {
  margin-top: 0;
}
.cols-wrapper:after {
  content: "";
  display: table;
  clear: both;
}
.cols-wrapper--no-padding {
  margin-left: 0;
  margin-right: 0;
}
.cols-wrapper--no-padding > .cols > .col {
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 767px) {
  .cols-wrapper--mobile-plain > .cols {
    display: block;
    margin-bottom: -1.875rem;
  }
}
@media (max-width: 767px) {
  .cols-wrapper--mobile-plain > .cols > .col {
    display: block;
    width: auto;
    margin-bottom: 1.875rem;
  }
}
@media (max-width: 767px) {
  .cols-wrapper--mobile-plain.cols-wrapper--form > .cols {
    margin-bottom: -3.125rem;
  }
}
@media (max-width: 767px) {
  .cols-wrapper--mobile-plain.cols-wrapper--form > .cols > .col {
    margin-bottom: 3.125rem;
  }
}

.cols-wrapper--form {
  margin-left: -1.5625rem;
  margin-right: -1.5625rem;
}
.cols-wrapper--form > .cols > .col {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}

.cols--reverse {
  direction: rtl;
}
.cols--reverse > .col {
  direction: ltr;
}

.col--fit {
  width: 1px;
}

.col--1-12 {
  width: 8.3333333333%;
}

.col--2-12 {
  width: 16.6666666667%;
}

.col--3-12 {
  width: 25%;
}

.col--4-12 {
  width: 33.3333333333%;
}

.col--5-12 {
  width: 41.6666666667%;
}

.col--6-12 {
  width: 50%;
}

.col--7-12 {
  width: 58.3333333333%;
}

.col--8-12 {
  width: 66.6666666667%;
}

.col--9-12 {
  width: 75%;
}

.col--10-12 {
  width: 83.3333333333%;
}

.col--11-12 {
  width: 91.6666666667%;
}

.col--12-12 {
  width: 100%;
}

.col--1-4 {
  width: 25%;
}

.col--3-4 {
  width: 75%;
}

.col--1-3 {
  width: 33.33%;
}

.col--2-3 {
  width: 66.66%;
}

.col--1-2 {
  width: 50%;
}

.cols--flex {
  display: flex;
  width: auto;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.cols--flex > .col {
  display: block;
}
@media (max-width: 767px) {
  .cols--flex > .col.col--2-3 {
    width: 100%;
  }
  .cols--flex > .col.col--1-3 {
    width: 100%;
  }
  .cols--flex > .col.col--1-2 {
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .cols--flex > .col.col--1-4 {
    width: 100%;
  }
  .cols--flex > .col.col--3-4 {
    width: 100%;
  }
}

/*

Textfields

*/
textarea,
input,
button {
  -webkit-font-smoothing: antialiased;
  outline: none;
  line-height: 1.25;
  font-family: inherit;
}

.textfield {
  position: relative;
  z-index: 0;
  display: inline-block;
  font-family: "Lato", Arial, sans-serif;
  word-spacing: 0.2em;
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 1.2857142857;
  background: transparent;
  color: #000;
  padding: 0.8125rem 0;
  width: 100%;
  border: none;
  box-shadow: inset 0 -0.0625rem 0 #585154;
  border-radius: 0;
  -webkit-appearance: none !important;
  outline: none;
  resize: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
}
@media (max-width: 1023px) {
  .textfield {
    font-size: 1.375rem;
    padding: 0.625rem 0;
  }
}
@media (max-width: 767px) {
  .textfield {
    font-size: 1.125rem;
  }
}
.textfield:hover {
  box-shadow: inset 0 -0.0625rem 0 #000;
}
.textfield:focus {
  box-shadow: inset 0 -0.0625rem 0 #000;
  outline: none;
}
.textfield:invalid {
  background: transparent;
}
.textfield:-webkit-autofill {
  -webkit-text-fill-color: #000;
  box-shadow: inset 0 -0.0625rem 0 #585154, inset 0 0 0 18.75rem #fff;
}
.textfield.error {
  box-shadow: inset 0 -0.0625rem 0 #f66, inset 0 0 0 18.75rem #fff;
}
.textfield::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.textfield::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.85);
  opacity: 1;
}
.textfield::-moz-placeholder {
  color: rgba(0, 0, 0, 0.85);
  opacity: 1;
}
.textfield:-moz-placeholder {
  color: rgba(0, 0, 0, 0.85);
  opacity: 1;
}
.textfield:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.85);
  opacity: 1;
}
.textfield--w-icon {
  padding-right: 4rem;
}
.textfield-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 4rem;
  font-size: 1.25rem;
}
.textfield[disabled], .textfield.disabled {
  color: #fff;
  opacity: 0.6;
  border-color: transparent;
  cursor: default;
  cursor: not-allowed;
  z-index: 2;
}

textarea.textfield {
  white-space: pre-wrap;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  -webkit-overflow-scrolling: touch;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}

/*

Buttons

*/
.btn {
  position: relative;
  display: inline-block;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  text-decoration: none;
  text-transform: none;
  text-align: center;
  font-family: "Lato", Arial, sans-serif;
  word-spacing: 0.2em;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.2857142857;
  letter-spacing: 0;
  border: none;
  padding: 0.75rem 2.1875rem;
  min-width: 3.375rem;
  min-height: 3.375rem;
  vertical-align: middle;
  border-radius: 0;
  color: rgba(0, 0, 0, 0.85) !important;
  -webkit-appearance: none;
  background: #fff;
  -webkit-user-select: none;
          user-select: none;
  backface-visibility: hidden;
  box-sizing: border-box;
  transition: color 0.3s, background-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
  z-index: 0;
}
@media (max-width: 1023px) {
  .btn {
    font-size: 1.375rem;
    padding: 0.75rem 1.5625rem;
    min-width: 3rem;
    min-height: 3rem;
  }
}
@media (max-width: 767px) {
  .btn {
    font-size: 1rem;
    padding: 0.375rem 0.75rem;
  }
}
.btn:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #000;
  content: "";
  transition: all 0.3s;
}
.btn::-moz-focus-inner {
  padding: 0 !important;
  border: 0 none !important;
}
.btn:hover {
  text-decoration: none;
  color: #fff !important;
  background-color: #000;
}
.btn.active, .btn:active {
  text-decoration: none;
  color: #fff !important;
  background-color: #000;
}
.btn[disabled], .btn.disabled {
  background: #fff;
  color: rgba(0, 0, 0, 0.85) !important;
  cursor: default;
  cursor: not-allowed;
  opacity: 0.5;
}
.btn + .btn {
  margin-left: 1.25rem;
}
.btn > input[type=file] {
  opacity: 0;
  font-size: 7.5rem;
  position: absolute;
  top: -1.25rem;
  right: -0.625rem;
  vertical-align: top;
  z-index: 3;
  cursor: pointer;
}
.btn .icon {
  font-size: 4.0625rem;
  margin: -0.3125rem 1.875rem;
}
.btn .icon:first-child {
  margin-left: 0;
}
.btn .icon:last-child {
  margin-right: 0;
}
.btn--trigger-parent:after {
  position: absolute;
  top: -31.25rem;
  right: -31.25rem;
  bottom: -31.25rem;
  left: -31.25rem;
  content: "";
}
.btn--fullwidth {
  width: 100%;
  margin-top: 1.25rem;
}
.btn--fullwidth:first-child {
  margin-top: 0;
}

/*

Loaders

*/
.loader {
  display: inline-block;
  vertical-align: middle;
  height: 2.5rem;
  width: 2.5rem;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  text-indent: 0;
}
.btn .loader, .loader-set-absolute > .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1.25rem 0 0 -1.25rem;
}
.loading > .loader {
  opacity: 1;
}
.loader.active {
  opacity: 1;
}
.loader * {
  fill: currentColor;
}
.btn .loader * {
  fill: currentColor;
}
.loader svg {
  width: 100%;
  height: 100%;
}

.btn.loading {
  pointer-events: none;
  background: none !important;
  color: transparent !important;
}
.btn.loading:before {
  opacity: 0 !important;
}

.btn__loader-inner {
  transition: opacity 0.3s;
}
.loading > .btn__loader-inner {
  opacity: 0;
}

/*

Expansible content

 */
.expand-it-container {
  position: relative;
  overflow: hidden;
  max-height: 0;
  pointer-events: none;
  transition: all 0.4s ease-in-out;
}
.expand-it-container > .expand-it-inner {
  position: relative;
  top: -6249.9375rem;
  transition-timing-function: ease-in-out;
  transition-delay: 0.4s;
}

.expand-it-container.overflow-visible {
  overflow: visible;
  max-height: 99999px !important;
}

.expand-it-container.active {
  max-height: 99999px;
  pointer-events: auto;
}
.expand-it-container.active > .expand-it-inner {
  top: 0;
  transition-delay: 0s;
}

/*

Form's layout

*/
.form {
  position: relative;
  margin-top: 3.75rem;
}
.form:after {
  content: "";
  display: table;
  clear: both;
}
.form:first-child {
  margin-top: 0;
}
.form__items {
  position: relative;
  margin-top: 1.25rem;
}
.form__items:after {
  content: "";
  display: table;
  clear: both;
}
.form__items:first-child {
  margin-top: 0;
}
.form__item {
  position: relative;
  margin-top: 2.5rem;
}
.form__item:after {
  content: "";
  display: table;
  clear: both;
}
.form__item:first-child {
  margin-top: 0;
}
@media (max-width: 1023px) {
  .form__item {
    margin-top: 1.875rem;
  }
}
.form__item-label {
  display: block;
  font-size: 0.8125rem;
  line-height: 1.6923076923;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-top: 0.375rem;
}
.form__item-label:first-child {
  margin-top: 0;
}
.form__item-field {
  position: relative;
  margin-top: 0.375rem;
}
.form__item-field:first-child {
  margin-top: 0;
}
.form__item--checkboxes {
  margin-top: 1.875rem;
}
.form__actions {
  position: relative;
  margin-top: 3.4375rem;
}
.form__actions:first-child {
  margin-top: 0;
}
@media (max-width: 1023px) {
  .form__actions {
    margin-top: 2.8125rem;
  }
}
.form__error {
  padding: 0.3125rem 0 0;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #f66;
  animation: 0.5s ease-out;
  animation-iteration-count: 2;
  animation-fill-mode: both;
  animation-delay: 0.3s;
  animation-name: pulsateError;
}
@media (max-width: 1023px) {
  .form__error {
    font-size: 0.75rem;
    line-height: 1.3333333333;
  }
}
.form__item-field--error-absolute .form__error {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  margin-bottom: 0;
}
.form__item-field--error-white .form__error {
  color: #fff;
}
.form__error.active {
  animation-name: pulsateError;
}
.form__message {
  display: none;
}
.form__message.active {
  display: block;
}
.form__tip {
  margin-top: 0.625rem;
  font-size: 0.75rem;
  line-height: 1.25;
}
.form__tip:first-child {
  margin-top: 0;
}
.form.inactive .form__items {
  display: none;
}

@keyframes pulsateError {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.form-required {
  color: #f5203a;
}

/*

Sliders

 */
.swiper-slider {
  position: relative;
  margin-top: 4.0625rem;
}
.swiper-slider:first-child {
  margin-top: 0;
}

.swiper-lazy-preloader {
  top: 50% !important;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  transform: translateY(-50%);
  background: none;
  margin-top: 0;
  margin-left: 0 !important;
  height: 4.0625rem;
  width: 4.0625rem;
  border-radius: 6.25rem;
  font-size: 1.25rem;
  background: transparent;
  border: 1px solid #000;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  outline: none;
  -webkit-user-select: none;
          user-select: none;
  transition: all 0.3s;
  line-height: 1;
}
.swiper-button-prev .icon,
.swiper-button-next .icon {
  vertical-align: top;
}
.swiper-button-prev:before,
.swiper-button-next:before {
  position: absolute;
  top: -1.25rem;
  right: -1.25rem;
  bottom: -1.25rem;
  left: -1.25rem;
  content: "";
}
.swiper-button-prev:after,
.swiper-button-next:after {
  content: "";
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.3);
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.5;
}

.swiper-button-disabled {
  pointer-events: none;
}

.swiper-pagination-wrapper {
  margin-top: 8.125rem;
}
.swiper-pagination-wrapper:first-child {
  margin-top: 0;
}
@media (max-width: 1023px) {
  .swiper-pagination-wrapper {
    margin-top: 4.6875rem;
  }
}
@media (max-width: 767px) {
  .swiper-pagination-wrapper {
    margin-top: 3.125rem;
  }
}

.swiper-pagination-container {
  position: relative;
}
.swiper-pagination-container:before {
  position: absolute;
  top: 0.4375rem;
  right: 0;
  left: 0;
  border-bottom: 1px solid #585154;
  content: "";
}
@media (max-width: 1023px) {
  .swiper-pagination-container:before {
    top: 0.3125rem;
  }
}

.swiper-pagination {
  position: absolute;
  z-index: 1;
  pointer-events: none;
  text-align: center;
  font-size: 0;
  line-height: 1;
  min-height: 0.9375rem;
}
@media (max-width: 1023px) {
  .swiper-pagination {
    min-height: 0.6875rem;
  }
}
.swiper-pagination.swiper-pagination-bullets {
  bottom: 2.1875rem;
  left: 0;
  right: 0;
  width: auto;
  margin-left: -0.3125rem;
  margin-right: -0.3125rem;
  margin-bottom: -0.625rem;
  pointer-events: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 0.3125rem 0.625rem;
  pointer-events: auto;
}
.swiper-pagination.swiper-pagination-bullets .swiper-pagination-bullet-active {
  pointer-events: none;
}
.swiper-pagination--static {
  margin-top: 4.375rem;
  position: relative;
}
.swiper-pagination--static:first-child {
  margin-top: 0;
}
.swiper-pagination--static.swiper-pagination-bullets {
  bottom: auto;
}
.swiper-pagination-bullet {
  position: relative;
  display: inline-block;
  vertical-align: top;
  pointer-events: auto;
  height: 0.9375rem;
  width: 0.9375rem;
  border-radius: 6.25rem;
  background-color: #585154;
  border: 1px solid transparent;
  opacity: 1;
  outline: none;
  transition: background-color 0.3s, opacity 0.3s;
}
@media (max-width: 1023px) {
  .swiper-pagination-bullet {
    height: 0.6875rem;
    width: 0.6875rem;
  }
}
.swiper-pagination-bullet:before {
  position: absolute;
  top: -0.3125rem;
  right: -0.3125rem;
  bottom: -0.3125rem;
  left: -0.3125rem;
  border-radius: 6.25rem;
  content: "";
}
.swiper-pagination-bullet:hover {
  background-color: #000;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background-color: #000;
}
.swiper-pagination-bullet-active:hover {
  background-color: #000;
}

.swiper-slider--start.swiper-slider--end .swiper-pagination-bullets {
  display: none;
}

.swiper-pagination-bullet-active:first-child:last-child {
  display: none;
}

.swiper-pagination-bullet-timer {
  display: none;
}

.swiper-slide {
  height: auto;
}
.swiper-slide img.swiper-lazy {
  opacity: 0;
  transition: all 0.3s;
}
.swiper-slide img.swiper-lazy-loaded {
  opacity: 1;
}

html {
  -webkit-text-size-adjust: 100%;
  background: #fff;
  height: 100%;
}

body {
  position: relative;
  font-family: "Lato", Arial, sans-serif;
  line-height: 1.3125;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  background: #fff;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  display: flex;
  flex-direction: column;
  min-width: 20rem;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
}

html, body {
  overflow-x: hidden;
  font-size: 16px;
}
@media (max-width: 1350px) {
  html, body {
    font-size: 1.1851851852vw;
  }
}
@media (max-width: 1023px) {
  html, body {
    font-size: 2.0833333333vw;
  }
}
@media (max-width: 767px) {
  html, body {
    font-size: 4.4444444444vw;
  }
}

*, *:before, *:after {
  box-sizing: border-box;
}

.no-animation-only-this {
  transition: none !important;
}

.no-animation {
  animation-delay: 0s !important;
  animation-duration: 0s !important;
}
.no-animation *:before, .no-animation *:after {
  animation-delay: 0s !important;
  animation-duration: 0s !important;
}

.no-transition-only-this {
  transition: none !important;
}

.no-transition {
  transition: none !important;
}
.no-transition *, .no-transition *:before, .no-transition *:after {
  transition: none !important;
}

::selection {
  background: #eee;
  color: #000;
  text-shadow: none;
}

.no-touchevents body ::-webkit-scrollbar {
  -webkit-appearance: none;
}
.no-touchevents body ::-webkit-scrollbar:vertical {
  width: 0.3125rem;
}
.no-touchevents body ::-webkit-scrollbar:horizontal {
  height: 0.3125rem;
}
.no-touchevents body ::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.05) !important;
  border-radius: 0;
  border: none;
}
.no-touchevents body ::-webkit-scrollbar-track {
  border-radius: 0;
  margin: 0;
  border: none;
  background-color: rgba(255, 255, 255, 0.03) !important;
}

.wrap {
  position: relative;
  z-index: 0;
  float: left;
  width: 100%;
  min-height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: 83.75rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin: 0 auto;
}
@media (max-width: 1023px) {
  .container {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}
@media (max-width: 767px) {
  .container {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
.container.fullwidth {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.custom-scrollbar ::-webkit-scrollbar {
  -webkit-appearance: none;
}
.custom-scrollbar ::-webkit-scrollbar:vertical {
  width: 0.3125rem;
}
.custom-scrollbar ::-webkit-scrollbar:horizontal {
  height: 0.3125rem;
}
.custom-scrollbar ::-webkit-scrollbar-track {
  border-radius: 6.25rem;
  border: none;
  background-color: rgba(88, 81, 84, 0.1) !important;
}
.custom-scrollbar ::-webkit-scrollbar-thumb {
  background-color: rgba(88, 81, 84, 0.2) !important;
  border-radius: 6.25rem;
  border: none;
}
.custom-scrollbar--dark ::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.05) !important;
}
.custom-scrollbar--dark ::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

/*
Лейаут шапки
*/
.header {
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  z-index: 40;
  line-height: 1.2;
  backface-visibility: hidden;
  will-change: transform;
  transition: all 0.4s;
}
.header--fixed {
  position: fixed;
}
.header--fixed.header--hidden {
  transform: translateY(-100%);
}
.header--fixed.header--scrolled {
  background-color: hsla(9, 87%, 96%, 0.95);
}
.header-replace {
  height: 13.125rem;
}
@media (max-width: 1900px) {
  .header-replace {
    height: 10rem;
  }
}
@media (max-width: 1023px) {
  .header-replace {
    height: 7.5rem;
  }
}
@media (max-width: 767px) {
  .header-replace {
    height: 5rem;
  }
}
.header + .header-replace {
  display: none;
}
.header--fixed + .header-replace {
  display: block;
}
.header--over + .header-replace {
  display: none;
}
.header .col {
  transition: height 0.4s;
}
.header .col--cbutton {
  width: 1%;
}
.header .col--logo {
  width: 1%;
}
.header__inner {
  position: relative;
  z-index: 2;
}
.header .col {
  height: 13.125rem;
  transition: height 0.4s;
}
@media (min-width: 1024px) {
  .header--scrolled.header .col {
    height: 6rem;
  }
}

@media (max-width: 1900px) {
  .header .col {
    height: 10rem;
  }
}
@media (max-width: 1023px) {
  .header .col {
    height: 7.5rem;
  }
}
@media (max-width: 767px) {
  .header .col {
    height: 4rem;
  }
}
.header .hmenu {
  top: 0.4375rem;
  transition: top 0.4s;
}
.header--scrolled.header .hmenu {
  top: 0;
}

.header--scrolled .icon--logo {
  width: 2.328358209em;
}

.cols-wrapper--header > .cols {
  table-layout: auto;
}
.cols-wrapper--header > .cols > .col {
  position: relative;
  vertical-align: middle;
  line-height: 1;
  transition: height 0.3s;
}
.cols-wrapper--header > .cols > .col--fit {
  width: 1px;
}
/*
Лого
*/
.logo {
  display: inline-block;
  text-decoration: none !important;
  transition: all 0.3s;
  white-space: nowrap;
  font-family: "Goldman", Arial, sans-serif;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 0.97;
  word-spacing: 0.2em;
  text-transform: uppercase;
}
@media (max-width: 1023px) {
  .logo {
    font-size: 1.125rem;
  }
}
.logo .icon {
  vertical-align: top;
}
.header .logo[href^="#"] {
  pointer-events: none;
}
.header--scrolled .logo[href^="#"] {
  pointer-events: auto;
}

.hmenu {
  position: relative;
  margin-top: 0.625rem;
}
.hmenu:first-child {
  margin-top: 0;
}
.hmenu__list {
  display: inline-block;
  vertical-align: top;
  min-width: 100%;
  font-size: 0;
  margin-left: -4.6875rem;
  margin-bottom: -0.9375rem;
}
@media (max-width: 1023px) {
  .hmenu__list {
    margin-left: -3.75rem;
  }
}
.hmenu__item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.2222222222;
  margin-left: 4.6875rem;
  margin-bottom: 0.9375rem;
}
@media (max-width: 1023px) {
  .hmenu__item {
    margin-left: 3.75rem;
  }
}
.hmenu__item.active > .hmenu__link:after {
  opacity: 1;
}
.hmenu__link {
  position: relative;
  padding: 0.125rem 0;
  color: inherit;
  text-decoration: none !important;
  transition: all 0.3s;
}
.hmenu__link:before {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid;
  border-color: currentColor;
  content: "";
  transform-origin: 0 50%;
  transform: scaleX(0);
  transition: all 0.3s;
}
.hmenu__link:after {
  position: absolute;
  top: -0.9375rem;
  right: -1.875rem;
  bottom: -0.9375rem;
  left: -1.875rem;
  border-radius: 6.25rem;
  background-color: rgba(88, 81, 84, 0.15);
  opacity: 0;
  transform: translateY(0.0625rem);
  content: "";
  transition: all 0.3s;
}
@media (max-width: 1023px) {
  .hmenu__link:after {
    top: -0.625rem;
    right: -1.25rem;
    bottom: -0.625rem;
    left: -1.25rem;
  }
}
.hmenu__link:hover:before {
  transform: scaleX(1);
}

/*
Кнопка-гамбургер открытия меню для мобильных
*/
.cbutton {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  height: 1.25rem;
  width: 2rem;
  text-align: center;
  font-family: Arial, sans-serif;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  z-index: 5;
  transition: all 0.3s;
}
.cbutton:hover {
  text-decoration: none;
}
.cbutton:active {
  text-decoration: none;
}
.cbutton:before {
  position: absolute;
  top: -0.625rem;
  right: -0.625rem;
  bottom: -0.625rem;
  left: -0.625rem;
  content: "";
  z-index: 1;
}
.cbutton__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  line-height: 1;
}
.cbutton__item {
  position: relative;
  display: block;
  height: 0.125rem;
  width: 2rem;
  background: currentColor;
  margin-top: 0.4375rem;
  transition: transform 0.5s, opacity 0.3s;
}
.cbutton__item:first-child {
  margin-top: 0;
}
.menu-overlay-active .cbutton__item {
  opacity: 0;
  transform: translateY(-0.5625rem) rotate(-45deg);
}
.menu-overlay-active .cbutton__item:first-child {
  opacity: 1;
  transform: translateY(0.5625rem) rotate(135deg);
}
.menu-overlay-active .cbutton__item:first-child + * {
  opacity: 1;
  transform: rotate(45deg);
}

.menu-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  transform: translate3d(0, -100%, 0);
  z-index: -1;
  visibility: hidden;
  transition: transform 0.5s, opacity 0.5s, visibility 0s 0.5s, z-index 0s 0.5s;
}
.menu-overlay-active .menu-overlay {
  z-index: 0;
  transform: none;
  visibility: visible;
  transition-delay: 0s;
}
.menu-overlay__content {
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 11.25rem 0 6.25rem;
  transition: all 0.4s;
}
@media (max-height: 53.125rem) {
  .menu-overlay__content {
    padding: 9.375rem 0 4.6875rem;
  }
}
.menu-overlay .hmenu {
  margin-top: 2.5rem;
  text-align: center;
}
.menu-overlay .hmenu:first-child {
  margin-top: 0;
}
.menu-overlay .hmenu__list {
  margin-bottom: -2.5rem;
}
@media (max-height: 767px) {
  .menu-overlay .hmenu__list {
    margin-bottom: -1.875rem;
  }
}
.menu-overlay .hmenu__item {
  display: block;
  font-size: 1.125rem;
  line-height: 1.7777777778;
  margin-bottom: 2.5rem;
}
@media (max-height: 767px) {
  .menu-overlay .hmenu__item {
    margin-bottom: 1.875rem;
  }
}
.menu-overlay .hmenu__link {
  border-bottom: none;
}

.menu-overlay-bg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: -2;
  background: #fff;
  transition: opacity 0.5s 0.15s, visibility 0s 0.6s, z-index 0s 0.6s;
}
.menu-overlay-active .menu-overlay-bg {
  opacity: 1;
  visibility: visible;
  z-index: 35;
  transition-delay: 0s;
}

img.b-lazy {
  min-width: 1px;
  min-height: 1px;
  opacity: 0;
  transition: opacity 0.5s;
}

img.b-loaded {
  opacity: 1;
}

.img-to-bg-lazy {
  opacity: 0;
  transition: opacity 0.5s;
}
.img-to-bg-lazy-loaded {
  opacity: 1;
}

.image {
  display: block;
  position: relative;
  padding-bottom: 100%;
  margin-top: 8.4375rem;
  margin-bottom: 8.4375rem;
  background-color: rgba(88, 81, 84, 0.4);
}
@media (max-width: 1023px) {
  .image {
    margin-top: 4.6875rem;
    margin-bottom: 4.6875rem;
  }
}
@media (max-width: 767px) {
  .image {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
}
.image--ml {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}
@media (max-width: 1023px) {
  .image--ml {
    margin-top: 4.0625rem;
    margin-bottom: 4.0625rem;
  }
}
@media (max-width: 767px) {
  .image--ml {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
  }
}
.image:first-child {
  margin-top: 0;
}
.image:last-child {
  margin-bottom: 0;
}
.image--transparent {
  background-color: transparent;
}
.image--rounded {
  border-radius: 50%;
}
.image--rounded .image__inner {
  border-radius: 50%;
}
.image__inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: center center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image__inner--contain {
  background-size: contain;
}
.image__bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
.image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: -100;
}

/*
Content Section
*/
.section {
  position: relative;
  z-index: 1;
  padding: 7.8125rem 0;
}
@media (max-width: 1900px) {
  .section {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
}
@media (max-width: 1023px) {
  .section {
    padding-top: 4.6875rem;
    padding-bottom: 4.6875rem;
  }
}
@media (max-width: 767px) {
  .section {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
}
.section--bring-to-front {
  z-index: 2;
}
.section--bring-to-back {
  z-index: 0;
}
.section--flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section--flex-start {
  justify-content: flex-start;
}
.section--flex-end {
  justify-content: flex-end;
}
.section--fullheight {
  flex: auto 1 1;
  min-height: 100%;
}
.section--nopadding {
  padding: 0;
}
.section__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: center center no-repeat;
  background-size: cover;
  z-index: -1;
  overflow: hidden;
}
.section__bg--z-index-auto {
  z-index: auto;
}
.section__bg--contain {
  background-size: contain;
}
.section__bg-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: center center no-repeat;
  background-size: cover;
}
.section__bg img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: -100;
}

.section--hero {
  padding-top: 17.1875rem;
  padding-bottom: 14.0625rem;
  margin-top: 0rem;
}
@media (max-width: 1900px) {
  .section--hero {
    margin-top: 8rem;
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
}
@media (max-width: 1023px) {
  .section--hero {
    margin-top: 3rem;
    padding-top: 4.6875rem;
    padding-bottom: 4.6875rem;
  }
}
@media (max-width: 767px) {
  .section--hero {
    margin-top: 0rem;
    padding-top: 4.6875rem;
    padding-bottom: 3.125rem;
  }
}

.summary {
  font-size: 1.3125rem;
  line-height: 1.3333333333;
  letter-spacing: 0.07em;
  margin-top: 1.5rem;
}
.summary:first-child {
  margin-top: 0;
}
@media (max-width: 1023px) {
  .summary {
    margin-top: 3.75rem;
    font-size: 1rem;
  }
}
@media (max-width: 767px) {
  .summary {
    margin-top: 1.875rem;
  }
}
.summary--large {
  font-size: 1rem;
  line-height: 2;
  margin-top: -10rem;
}
@media (max-width: 1023px) {
  .summary--large {
    font-size: 1rem;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .summary--large {
    font-size: 1rem;
    margin-top: 0;
  }
}
.summary--large p {
  margin-top: 1.25rem;
}
.summary--large p:first-child {
  margin-top: 0;
}
@media (max-width: 767px) {
  .summary--large p {
    margin-top: 1.25rem;
  }
}

.astronaut {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  margin: 1rem 0 0 14.5rem;
  max-width: 20.5rem;
  opacity: 0.6;
  z-index: -1;
  pointer-events: none;
}
@media (max-width: 1023px) {
  .astronaut {
    margin: -7.5rem 0 0 4.0625rem;
    max-width: 28.125rem;
    display: none;
  }
}
@media (max-width: 767px) {
  .astronaut {
    max-width: 18.75rem;
    margin: -6.25rem 0 0 -1.25rem;
    display: none;
  }
}
.astronaut__inner {
  transform-origin: 50% 50%;
}
.astronaut__inner svg {
  height: 250px;
  width: 250px;
}
.astronaut .b-loaded {
  transition: all 3s;
}

@media (max-width: 1023px) {
  .section--hero-services {
    padding-top: 4.6875rem;
    padding-bottom: 4.6875rem;
  }
}
@media (max-width: 767px) {
  .section--hero-services {
    padding-top: 4.6875rem;
    padding-bottom: 0;
  }
}

.title2 {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 1.75rem;
  text-transform: uppercase;
  text-align: right;
  overflow: hidden;
  word-spacing: 0.05em;
  padding: 0.8125rem 0 0;
}
@media (max-width: 1023px) {
  .title2 {
    font-size: 1rem;
    padding-top: 0.375rem;
  }
}
.title2:before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border-top: 0.125rem solid currentColor;
  content: "";
}
@media (max-width: 1023px) {
  .title2:before {
    border-top-width: 1px;
  }
}
.title2__main {
  font-size: 4.25rem;
  line-height: 0.7;
  margin: 0 -0.05em;
}
@media (max-width: 1023px) {
  .title2__main {
    font-size: 4.0625rem;
  }
}
@media (max-width: 767px) {
  .title2__main {
    font-size: 3.625rem;
  }
}
.title2__sub {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 1.75rem;
  line-height: 0.9;
  margin-right: 4.375rem;
}
@media (max-width: 1023px) {
  .title2__sub {
    font-size: 1rem;
    margin-right: 2.5rem;
  }
}
@media (max-width: 767px) {
  .title2__sub {
    margin-right: 1.875rem;
  }
}
.title2__sub:before {
  position: absolute;
  top: 50%;
  right: 100%;
  bottom: 0;
  margin-right: 0.875rem;
  width: 9999px;
  margin-top: -0.0625rem;
  border-top: 0.125rem solid currentColor;
  content: "";
}
@media (max-width: 1023px) {
  .title2__sub:before {
    border-top-width: 1px;
  }
}
.title2--moon {
  margin-right: 26.25rem;
  top: 4.0625rem;
}
@media (max-width: 1023px) {
  .title2--moon {
    margin-right: 11.25rem;
  }
}
@media (max-width: 767px) {
  .title2--moon {
    margin-right: 0;
  }
}

.moon-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.moon {
  width: 100%;
  max-width: 34.375rem;
  margin: 0 auto;
  z-index: -1;
  opacity: 0.7;
  pointer-events: none;
}
@media (max-width: 1023px) {
  .moon {
    max-width: 22.5rem;
  }
}
.moon__inner {
  position: relative;
  transform-origin: 50% 50%;
  padding-bottom: 100%;
}
.moon__inner img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.moon .b-loaded {
  transition: all 3s;
}

@media (min-width: 767px) {
  .mw-mobile-400 {
    max-width: 25rem;
    margin: 0 auto;
  }
}

.cards {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex: 0 1 100%;
}
.cards__full {
  flex: 0 1 100%;
}
.cards__half {
  flex: 0 1 100%;
  margin-bottom: 40px;
}
@media screen and (min-width: 767px) {
  .cards__half {
    flex: 0 1 48%;
  }
}
.cards__third {
  flex: 0 1 100%;
}
@media screen and (min-width: 767px) {
  .cards__third {
    flex: 0 1 30%;
  }
}
.cards__card {
  background-color: #fff;
  box-shadow: 0 26px 70px 0 rgba(5, 5, 5, 0.4);
  position: relative;
  margin-bottom: 40px;
  padding: 0 40px 40px;
  color: #000;
}
.cards__card__title {
  margin: 2.1rem 0 1.35rem;
}
.cards__card__padded_title {
  padding: 60px 0 1.2rem;
}
.cards__card__text {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 40px;
}
.cards__card .btn {
  font-size: 20px;
}
.cards__card__button {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
  display: inline-block;
  margin-top: 20px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  text-decoration: none;
}

.itgbs {
  margin: 0 auto;
  margin-top: 3.125rem;
}
.itgbs:first-child {
  margin-top: 0;
}
@media (max-width: 1023px) {
  .itgbs {
    margin-top: 3.75rem;
  }
}
@media (max-width: 767px) {
  .itgbs {
    margin-top: 1.875rem;
  }
}
.itgbs__container {
  margin: 0 -3.125rem;
}
.itgbs__container:after {
  content: "";
  display: table;
  clear: both;
}
@media (max-width: 1023px) {
  .itgbs__container {
    margin: 0 -1.5625rem;
  }
}
@media (max-width: 767px) {
  .itgbs__container {
    margin: 0;
  }
}
.itgbs__inner {
  float: left;
  width: 100%;
  font-size: 0;
  margin: -2.1875rem 0;
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 1023px) {
  .itgbs__inner {
    margin: -1.5625rem 0;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .itgbs__inner {
    margin: 0;
  }
}

.itgb {
  display: inline-block;
  vertical-align: top;
  font-size: 1rem;
  width: 100%;
  padding: 2.1875rem 3.125rem;
}
@media (max-width: 1023px) {
  .itgb {
    width: 100%;
    padding: 1.5625rem 1.5625rem;
    max-width: 31.25rem;
  }
}
@media (max-width: 767px) {
  .itgb {
    padding: 0;
    margin-top: -1px;
  }
}
.itgb__inner {
  display: block;
  display: flex;
  height: 100%;
  text-decoration: none !important;
  color: inherit;
  padding: 1.875rem 0;
  font-size: 20px;
}
@media (max-width: 767px) {
  .itgb__inner {
    border-top: 1px solid #585154;
    border-bottom: 1px solid #585154;
  }
}
.itgb__icon {
  width: 10rem;
  font-size: 4.5rem;
  text-align: right;
  flex: auto 0 0;
  padding: 0 3.125rem 0 0;
  align-self: center;
}
@media (max-width: 1023px) {
  .itgb__icon {
    width: 8.125rem;
    padding-right: 3.125rem;
  }
}
@media (max-width: 767px) {
  .itgb__icon {
    font-size: 3.75rem;
    width: 5rem;
    padding-right: 1.25rem;
  }
}
.itgb__content {
  flex: auto 1 1;
  padding: 1.25rem 0;
  min-height: 100%;
}
@media (max-width: 767px) {
  .itgb__content {
    border: none;
    padding: 0;
  }
}
.itgb__title {
  margin-top: 0.625rem;
  font-family: "Lato", Arial, sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.2222222222;
  word-spacing: 0.2em;
  margin-right: 6.25rem;
}
.itgb__title:first-child {
  margin-top: 0;
}
@media (max-width: 767px) {
  .itgb__title {
    margin-right: 0;
  }
}
.itgb__summary {
  margin-top: 0.625rem;
  font-weight: 500;
}
.itgb__summary:first-child {
  margin-top: 0;
}
.itgb img {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.icon--saturn {
  font-size: 4.5625rem;
}
@media (max-width: 767px) {
  .icon--saturn {
    font-size: 100%;
  }
}

.icon--drop-drupal {
  width: 0.9027777778em;
}

.icon--drop-sector {
  font-size: 3.9375rem;
}
@media (max-width: 767px) {
  .icon--drop-sector {
    font-size: 3.4375rem;
  }
}

.v-line-summary {
  margin-left: 50%;
  margin-top: 2.5rem;
}
.v-line-summary:first-child {
  margin-top: 0;
}
@media (max-width: 1023px) {
  .v-line-summary {
    margin-top: 1.875rem;
  }
}
@media (max-width: 767px) {
  .v-line-summary {
    margin-left: 0;
    text-align: center;
  }
}
.v-line-summary:before {
  display: block;
  margin-bottom: 3.125rem;
  height: 9.375rem;
  width: 1px;
  background: #585154;
  content: "";
}
@media (max-width: 1023px) {
  .v-line-summary:before {
    height: 6.25rem;
    margin-bottom: 1.875rem;
  }
}
@media (max-width: 767px) {
  .v-line-summary:before {
    margin-left: auto;
    margin-right: auto;
  }
}
.v-line-summary:before:last-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .image--m-fw {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
}

.ltgbs {
  position: relative;
  margin: 0 -0.9375rem;
  margin-top: 5rem;
  padding: 1.875rem 0;
}
.ltgbs:first-child {
  margin-top: 0;
}
.ltgbs:after {
  content: "";
  display: table;
  clear: both;
}
@media (max-width: 1023px) {
  .ltgbs {
    margin-top: 6.25rem;
    margin-left: -1.875rem;
    margin-right: -1.875rem;
  }
}
@media (max-width: 767px) {
  .ltgbs {
    margin-top: 3.125rem;
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    padding: 3.125rem 0;
  }
}
.ltgbs:before {
  position: absolute;
  top: 0;
  right: -624.9375rem;
  bottom: 0;
  left: -624.9375rem;
  z-index: -1;
  border-bottom: 1px solid #585154;
  content: "";
}
.ltgbs__inner {
  float: left;
  width: 100%;
  margin: -0.9375rem 0;
  font-size: 0;
}
@media (max-width: 1023px) {
  .ltgbs__inner {
    margin: -1.875rem 0;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .ltgbs__inner {
    margin: -1.25rem 0;
  }
}

.ltgb {
  display: inline-block;
  vertical-align: top;
  width: 33.33%;
  padding: 0.9375rem;
  font-size: 1rem;
  text-align: center;
}
@media (max-width: 1023px) {
  .ltgb {
    width: auto;
    padding: 1.875rem;
  }
}
@media (max-width: 767px) {
  .ltgb {
    display: block;
    padding: 1.25rem;
  }
}

.cols-wrapper--contact {
  margin-top: 5rem;
  padding: 0 4.375rem;
}
.cols-wrapper--contact:first-child {
  margin-top: 0;
}
@media (max-width: 1023px) {
  .cols-wrapper--contact {
    padding: 0;
    margin-top: 3.125rem;
  }
}
@media (max-width: 767px) {
  .cols-wrapper--contact {
    margin-top: 1.875rem;
  }
}
.cols-wrapper--contact .col--logo {
  padding-top: 5.9375rem;
  text-align: right;
}
@media (max-width: 1023px) {
  .cols-wrapper--contact .col--logo {
    padding-top: 3.4375rem;
  }
}
@media (max-width: 767px) {
  .cols-wrapper--contact .col--logo {
    display: none !important;
  }
}
.cols-wrapper--contact .col--contact {
  padding-top: 1rem;
  text-align: left;
}
@media (max-width: 1023px) {
  .cols-wrapper--contact .col--contact {
    padding-top: 3.4375rem;
  }
}
.cols-wrapper--contact .col--contact .row {
  padding: 0 0 50px 0;
}
.cols-wrapper--contact .col--contact .row i {
  margin-right: 10px;
  vertical-align: middle;
  margin-top: -5px;
}
@media (max-width: 1023px) {
  .cols-wrapper--contact .col--contact .row {
    padding: 0 0 20px 0;
  }
}
@media (max-width: 767px) {
  .cols-wrapper--contact .col--contact .row {
    padding: 0 0 50px 0;
  }
}

.form--contact {
  max-width: 27.5rem;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

.logo-w-text {
  display: inline-block;
  vertical-align: top;
  max-width: 13.4375rem;
  text-align: left;
  margin-top: 3.125rem;
}
.logo-w-text:first-child {
  margin-top: 0;
}
.logo-w-text__text {
  margin-top: 0.3125rem;
  padding-left: 3.9375rem;
}
.logo-w-text__text:first-child {
  margin-top: 0;
}

.sicons {
  display: inline-block;
  margin: 0 -0.9375rem -0.9375rem 0;
  font-size: 0;
}

.sicon {
  display: inline-block;
  vertical-align: top;
  line-height: 1;
  margin: 0 0.9375rem 0.9375rem 0;
  font-size: 1.875rem;
  color: #585154;
}
.sicon .icon {
  vertical-align: top;
}
.sicon:hover {
  color: #000;
}
.sicon:active {
  color: #000;
}

.fly-circle {
  position: absolute;
  z-index: -1;
  height: 50rem;
  width: 50rem;
  background: center center no-repeat;
  background-size: contain;
  transform-origin: 50% 50%;
  will-change: transform, opacity;
  pointer-events: none;
}
@media (max-width: 767px) {
  .fly-circle {
    display: none;
  }
}
.fly-circle--blue {
  background-image: url("data:image/svg+xml,%3Csvg width='802' height='813' viewBox='0 0 802 813' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.3' filter='url(%23filter0_f)'%3E%3Cpath d='M150.714 404.077C146.208 545.314 254.548 660.8 392.698 662.021C530.848 663.242 646.494 549.737 651 408.5C655.506 267.263 547.166 151.778 409.016 150.556C270.866 149.335 155.22 262.84 150.714 404.077Z' fill='%232491EA'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f' x='0.578613' y='0.546387' width='800.557' height='811.484' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='75' result='effect1_foregroundBlur'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
}
.fly-circle--red {
  height: 58.75rem;
  width: 58.75rem;
  background-image: url("data:image/svg+xml,%3Csvg width='752' height='731' viewBox='0 0 752 731' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.3' filter='url(%23filter0_f)'%3E%3Cellipse rx='175.578' ry='170.103' transform='matrix(-0.99996 -0.00896905 -0.0314284 0.999506 375.918 360.594)' fill='%23FF4016'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_f' x='0.263184' y='-9.43262' width='751.309' height='740.052' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='100' result='effect1_foregroundBlur'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
}
.fly-circle--header {
  left: 50%;
  margin-left: -9.375rem;
  top: -21.875rem;
}
.fly-circle--hero-bottom {
  top: 50%;
  margin-top: -25rem;
}
.fly-circle--hero-bottom:nth-child(1) {
  left: 0;
  height: 41.25rem;
  width: 41.25rem;
  margin-left: -16.25rem;
}
.fly-circle--hero-bottom:nth-child(2) {
  right: 0;
  height: 58.75rem;
  width: 58.75rem;
  margin-right: -37.5rem;
}
.fly-circle--clients-bottom {
  top: 50%;
  margin-top: -25rem;
}
.fly-circle--clients-bottom:nth-child(1) {
  left: 0;
  margin-top: 3.125rem;
  height: 50rem;
  width: 50rem;
  margin-left: -25rem;
}
.fly-circle--clients-bottom:nth-child(2) {
  right: 0;
  margin-top: -23.125rem;
  height: 46.25rem;
  width: 46.25rem;
  margin-right: -9.375rem;
}

.half-left {
  width: 50%;
}
@media (max-width: 767px) {
  .half-left {
    width: 100%;
  }
}

.half-right {
  width: 50%;
  margin-left: auto;
}
@media (max-width: 767px) {
  .half-right {
    width: 100%;
  }
}

.section--work,
.section--service,
.section--services,
.section--project,
.section--about {
  margin-top: 8rem;
}
@media (max-width: 1023px) {
  .section--work,
  .section--service,
  .section--services,
  .section--project,
  .section--about {
    margin-top: 5rem;
  }
}
@media (max-width: 767px) {
  .section--work,
  .section--service,
  .section--services,
  .section--project,
  .section--about {
    margin-top: 1rem;
  }
}

.section--project,
.section--process,
.section--about {
  padding-top: 5.3125rem;
}
@media (max-width: 1023px) {
  .section--project,
  .section--process,
  .section--about {
    padding-top: 4.6875rem;
  }
}
.section--project .vzblz,
.section--process .vzblz,
.section--about .vzblz {
  z-index: 1;
}
.section--project .nmright,
.section--process .nmright,
.section--about .nmright {
  margin-right: -200px;
}
@media (max-width: 767px) {
  .section--project .nmright,
  .section--process .nmright,
  .section--about .nmright {
    margin-right: 0px;
  }
}
.section--project .about-slider-text,
.section--process .about-slider-text,
.section--about .about-slider-text {
  position: relative;
}
.section--project .mrgntp,
.section--process .mrgntp,
.section--about .mrgntp {
  margin-top: 0 !important;
}
.section--project p,
.section--process p,
.section--about p {
  max-width: 40rem;
  font-size: 1.3125rem;
  line-height: 1.3333333333;
  letter-spacing: 0.07em;
}
@media (max-width: 1023px) {
  .section--project p,
  .section--process p,
  .section--about p {
    font-size: 1rem;
  }
}
.section--project p--large,
.section--process p--large,
.section--about p--large {
  font-size: 1rem;
  line-height: 2;
}
@media (max-width: 1023px) {
  .section--project p--large,
  .section--process p--large,
  .section--about p--large {
    font-size: 1rem;
  }
}
@media (max-width: 767px) {
  .section--project p--large,
  .section--process p--large,
  .section--about p--large {
    font-size: 1rem;
  }
}

.cols-wrapper--tgb {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}
@media (max-width: 1023px) {
  .cols-wrapper--tgb {
    margin-left: -1.5625rem;
    margin-right: -1.5625rem;
  }
}
@media (max-width: 767px) {
  .cols-wrapper--tgb > .cols {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    margin-bottom: -30px;
  }
}
.cols-wrapper--tgb > .cols > .col {
  padding: 0 2.5rem;
}
@media (max-width: 1023px) {
  .cols-wrapper--tgb > .cols > .col {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }
}
@media (max-width: 767px) {
  .cols-wrapper--tgb > .cols > .col {
    display: block;
    width: auto;
    margin-bottom: 30px;
  }
}

.about-slider {
  margin-top: 4rem;
  margin-bottom: 12.5rem;
}
.about-slider:last-child {
  margin-bottom: 0;
}
@media (max-width: 1023px) {
  .about-slider {
    margin-top: 11.25rem;
    margin-bottom: 7.5rem;
  }
}
@media (max-width: 767px) {
  .about-slider {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
}
.about-slider .swiper-pagination-container2 {
  max-width: 8.75rem;
  margin: 0 auto;
}
@media (max-width: 1023px) {
  .about-slider .swiper-pagination-container2 {
    max-width: 8.75rem;
  }
}
.about-slider .subtitle {
  font-size: 1rem;
  color: #959595;
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem;
}
.about-slider .subtitle:first-child {
  margin-top: 0;
}
.about-slider .subtitle:last-child {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .about-slider .subtitle {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}
.about-slider p {
  line-height: 1.75rem;
}
.about-block__pane {
  display: grid;
  grid-gap: 2rem;
  align-items: center;
}
@media (min-width: 767px) {
  .about-block__pane {
    grid-gap: 6rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

.about-block__head {
  padding-top: 4rem;
  margin-bottom: 4rem;
  border-top: 1px solid #585154;
}

.about-block__avatar {
  border: 10px solid white;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  background-size: contain;
  object-fit: contain;
}

.about-block__position {
  font-size: 1.5rem;
  color: #959595;
  text-transform: uppercase;
  text-align: center;
}
@media (min-width: 767px) {
  .about-block__position {
    text-align: left;
  }
}

.about-block__left {
  display: flex;
  justify-content: center;
}
@media (min-width: 767px) {
  .about-block__left {
    justify-content: flex-end;
  }
}

.about-block__right {
  align-self: flex-start;
}

.exps {
  margin-top: 4.375rem;
  border-bottom: 1px solid #585154;
}
.exps:first-child {
  margin-top: 0;
}
@media (max-width: 767px) {
  .exps {
    margin-top: 3.125rem;
  }
}

.exp {
  position: relative;
  border-top: 1px solid #585154;
  padding: 2.1875rem 4.375rem 2.1875rem 0;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 1023px) {
  .exp {
    overflow: hidden;
  }
}
@media (max-width: 1023px) {
  .exp {
    padding: 1.5625rem 0;
  }
}
.exp.active {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}
@media (max-width: 1023px) {
  .exp.active {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}
.exp__content {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.3125;
  color: rgba(0, 0, 0, 0.75);
}
@media (max-width: 1023px) {
  .exp__content-inner {
    padding-top: 1.875rem;
  }
}
.exp__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  width: 5rem;
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-radius: 50%;
  font-size: 1.375rem;
  line-height: 1;
}
@media (max-width: 1023px) {
  .exp__icon {
    height: 3.125rem;
    width: 3.125rem;
    font-size: 1.125rem;
  }
}
.exp__icon:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}
@media (max-width: 1023px) {
  .exp__icon:before {
    top: -999px;
    right: -999px;
    bottom: -999px;
    left: -999px;
  }
}
.exp.active .exp__icon:before {
  display: none;
}
.exp__icon:hover {
  border-color: #000;
}
.exp__icon .icon {
  top: 0.0625rem;
  vertical-align: top;
  transition: all 0.4s ease-in-out;
}
.exp.active .exp__icon .icon {
  transform: rotate(180deg);
}

.cols-wrapper--exp {
  margin-left: -3.125rem;
  margin-right: -3.125rem;
}
@media (max-width: 1023px) {
  .cols-wrapper--exp {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 1023px) {
  .cols-wrapper--exp > .cols {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }
}
.cols-wrapper--exp > .cols > .col {
  padding: 0 3.125rem;
}
@media (max-width: 1023px) {
  .cols-wrapper--exp > .cols > .col {
    display: block;
    padding: 0;
    width: auto;
  }
}
.cols-wrapper--exp > .cols > .col:first-child {
  padding-top: 0.625rem;
  width: 30rem;
}
@media (max-width: 1023px) {
  .cols-wrapper--exp > .cols > .col:first-child {
    padding-top: 0;
    width: auto;
  }
}
.cols-wrapper--exp > .cols > .col--title {
  padding-right: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.125rem;
}
@media (max-width: 1023px) {
  .cols-wrapper--exp > .cols > .col--icon {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.image-blk {
  background-color: black !important;
}

.services-bg-l {
  background-image: url("../static/pic/services-vertical.jpg");
  background-position-x: center;
  background-position-y: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.services-bg-r {
  background-image: url("../static/pic/services-vertical-f.jpg");
  background-position-x: center;
  background-position-y: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 1023px) {
  .testimonials-slider {
    margin-top: 11.25rem;
    margin-bottom: 7.5rem;
  }
}
@media (max-width: 767px) {
  .testimonials-slider {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
}
.testimonials-slider .swiper-pagination-container2 {
  max-width: 8.75rem;
  margin: 0 auto;
}
@media (max-width: 1023px) {
  .testimonials-slider .swiper-pagination-container2 {
    max-width: 8.75rem;
  }
}

.testimonial {
  position: relative;
  padding-left: calc(30px + 1rem);
}
@media (min-width: 1023px) {
  .testimonial {
    padding-left: calc(90px + 4rem);
    font-size: 1.25rem;
  }
}

.testimonial::before {
  content: "";
  display: block;
  background-image: url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAABiCAYAAACYsttCAAAFWklEQVR4nO2dzctVVRTGn/WGRV9Yg4rKNEgqEdSMJilEEy2IIKIICqIG5ayRDoJEG4QE0TCaFYTQICtCgjIqqIlg35kYFW8fhqZgFoVRPrHj3Lq93o99ztnnrnXW3r8/YK+1fvu5+5z7DUuQPIPkuaaaMoZbRyTPJ/kayS9IXm2gJXO4dUTySpKf8j+Ok7zVQGtmcOuI5PUkj/B0/iK5xUCL6rh1RHIDyV9GDDbMTpJnG2hXBbeOSN5D8o8pgw3YR/IKA23PFLeOSN5bHV91OExyvYH2Z4JbRyQfajDYgPBoeNjAGJ3i1hHJ+xsOtZBnSJ5pYKTkuHVE8s4WqR7FuyQvNjBaMtw6InlLjZuZOsyTvE59wAS4dRSKRzyNacNv4W5ZbcAEuHVEcinJQx0ONsyO8Dr5zIdsiVtHJM8j+cmMBhuwm+QFMxkwAW4dkRSSL894sAEHSF47oz1sjGtHJB9TGmxAeKPkqs53sQVuHZHcSPKU8nB7wiNshvtZC0uOkkoieRmAjwBc1L3GsfwMYJWIfKvYw1isOZpLtWJ1d/mC8mCBRwxvvl9HJB9VPtICu5MOlRiLjpJcAkiuBrAXgOZrz+FYWykiPyj2MBarjlpfAkguAvC88mCBzYY3368jktsNHGtvG7/rN+uolTSSK6o7Ws1kn6zuaA8q9jAW644aXwKqND1r4Fh7wvDm+3VE8kEDx1p4SfOspIMlpA+OGl0CSC4G8KWB57MbRORN5R5G0hdHTS8B2wwM9pLVza/ohaPaJ0D1LtJnADTfe/8dwAoRmVfsYSx9ctTkBNihPFjgKaubX9EbR7VOAJLrALzXqq32HAawXER+Ve5jJH1zVPcEeLJ5T8nYanXzK3rlKPoECJ9aBfB6q7ba8zmANSLyp3IfI+mjozonwLbmPSVji9XNr/DpqPrMujZ7kg6VmL46ij0BLHwHfbOBHibRS0dTA0DyBgA3N24pDa+KyIfKPYylz45iTgDtZBPAduUeptFbRxOfBVQ/OPCN8osaL4qI2a9+9d3RtBNgk/JgIdmPK9aPodeOxp4A1XfLv1d+Q8P6o7/3jiadAHcoD9aHR3/vHU0KwANtFk7ALhHZr9zDNHrvaOQlgOQSAPMpPjXcgvUi8r5i/Yl4cTSu+fuUB9trefMrXDgaN8BdbRduydPK9WNw4ei0SwDJawAcSLF4Q44BuFxETir2MBFPjkadAHe3XbQlOy1vfoUbR6MCcHuKhVvwnHL9GNw4+t8lgOSlAA6lWrwB+0VkpWL9qXhztPAEuC3Vwg3ZpVw/BleOFgZgY8rFG/CKcv0YXDn69xJAMoThKIALUxaowXcAlokIlepPxaOj4RNgreJggTcsb36FO0fDAbgp5cINeEe5fgzuHA0HYF3qxWvylnL9GNw5Gg7AjakXr8FBEflRsX4s7hz9EwCSywBcknrxGuxTrB2FV0eDE2BtF4vXwOwnfodw6WgQgDVdLF6DD5Trx+DS0SAAq7tYvAYfK9ePwaWjQQA0/4f2hIgcVawfi0tHc9Xv1y7vYvFIvlasHYVnR+EEWApgUVcFIvhKsXYsbh2FACzpavFITP6y9wLcOrIQgJ+U68fg1pGFABxTrh+DW0dzyu9uBY4o14/BrSMLATiuXD8Gt45CABZ3tbgj3DoKAThHuYcTyvVjcOtI86tNA04Z6ME6nTmaS/W/Qc5x62iu+o55YTJuHVm4BBQUKQHInBKAzCkByJwSgMwpAcicEoDMKQHInBKAzCkByJwSgMwpAcicEoDMKQHInBKAzCkByJwSgMwpAcicEoDMKQHInBKAzCkByJwSgMwpAcgZAH8DVwJr/QJDbOMAAAAASUVORK5CYII=");
  background-position: center;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-size: 30px;
  position: absolute;
  top: 0;
  left: 0;
}
@media (min-width: 1023px) {
  .testimonial::before {
    width: 90px;
    height: 90px;
    background-size: 90px;
  }
}

.testimonial__footer {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 1023px) {
  .testimonial__footer {
    padding-right: 15rem;
  }
}

.testimonial__cite {
  text-align: right;
  font-style: italic;
  display: flex;
  align-items: center;
}

.testimonial__cite::before {
  content: "";
  display: block;
  border-bottom: 1px solid #585154;
  width: 30px;
  margin-right: 1rem;
}

.section--good-company {
  padding-bottom: 0;
  margin: 0 -0.9375rem;
}

.section--good-company > .container {
  border-bottom: 1px solid #585154;
  padding-bottom: 7.8125rem;
}

.clients {
  margin: 3rem 0;
  position: relative;
}

.clients__logos {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.clients__logo {
  margin: 1rem;
}

.good-company-description {
  display: grid;
  grid-gap: 1rem;
  margin: 2rem 0;
  position: relative;
}
@media (min-width: 767px) {
  .good-company-description {
    grid-template-columns: repeat(2, 1fr);
  }
}

.good-company-description__content {
  grid-column: 2/span 1;
}

.page-title--case-study {
  margin-top: 0;
  max-width: 860px;
}

.section--case-study {
  padding-top: 0;
}

.tabs__head {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 -2rem;
  margin-bottom: 4.0625rem;
  overflow-x: auto;
}

.tabs__head::before {
  content: "";
  height: 1px;
  background-color: #585154;
  width: calc(100% - 4rem);
  position: absolute;
  left: 2rem;
  bottom: 0;
}

.tabs__tab {
  display: block;
  flex: 0 0 auto;
  margin: 0 1rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-family: "Lato", Arial, sans-serif;
  color: #888;
  font-weight: 500;
  font-size: 1.125rem;
}

.tabs__tab--active {
  color: black;
  font-weight: bold;
}

.tabs__slide {
  position: absolute;
  height: 4px;
  background-color: #2491ea;
  bottom: 0;
  transition: all 0.2s ease-out;
}

.tabs__tab-item {
  display: none;
}

.tabs__tab-item--active {
  display: block;
}

.case-study-list li {
  position: relative;
  text-indent: 0;
  margin: 1rem 0;
}

.case-study-list li::before {
  position: absolute;
  content: "";
  background: transparent url("../static/img/svg/icon-arrow-right.svg") no-repeat center left;
  height: 24px;
  top: 0;
  left: 0;
}

.case-study-vertical-line {
  height: 6.25rem;
  width: 2px;
  background-color: #585154;
  margin: 0 auto;
}
@media (min-width: 767px) {
  .case-study-vertical-line {
    height: 14.375rem;
    margin: 0;
  }
}

.flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.border-0 {
  border: 0 !important;
}

.padding-0 {
  padding: 0 !important;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin: 0.5rem auto;
}
.tags > .btn {
  font-size: 1rem;
  margin: auto;
  padding: 0.5rem 1rem;
  min-height: 2rem;
}

.notification-table {
  margin-top: -40px;
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
.notification-table table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: center;
}
.notification-table th, .notification-table td {
  border: 1px solid #4caf50; /* Green border */
  padding: 12px 15px;
}
.notification-table th {
  background-color: #e8f5e9; /* Light green background */
  font-weight: bold;
}
.notification-table td {
  font-weight: bold;
  color: #2e7d32; /* Dark green text color */
  background-color: #f1f8e9; /* Slightly lighter green background for rows */
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #f54654;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  border: 1px solid #dddddd;
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

/*
Лейаут футера
*/
.footer {
  position: relative;
  margin-top: auto;
  padding-top: 8.75rem;
}
@media (max-width: 1900px) {
  .footer {
    padding-top: 3.125rem;
  }
}
@media (max-width: 1023px) {
  .footer {
    padding-top: 0;
  }
}
.footer__inner {
  padding: 1.5625rem 0;
  border-top: 1px solid #585154;
}
@media (max-width: 1023px) {
  .footer__inner {
    padding: 1.25rem 0;
  }
}
@media (max-width: 767px) {
  .footer__inner {
    padding: 2.1875rem 0;
  }
}

.cols-wrapper--footer {
  margin: 0 -1.25rem;
}
.cols-wrapper--footer > .cols {
  table-layout: auto;
}
@media (max-width: 767px) {
  .cols-wrapper--footer > .cols {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}
.cols-wrapper--footer > .cols > .col {
  position: relative;
  vertical-align: middle;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
@media (max-width: 767px) {
  .cols-wrapper--footer > .cols > .col {
    flex: auto 1 1;
  }
}
.cols-wrapper--footer .col--social {
  flex: auto 0 0;
}

.fmenu {
  position: relative;
  margin-left: -4.375rem;
  margin-top: 0.625rem;
}
.fmenu:first-child {
  margin-top: 0;
}
@media (max-width: 1023px) {
  .fmenu {
    margin-left: -1.875rem;
  }
}
@media (max-width: 767px) {
  .fmenu {
    margin-left: 0;
  }
}
.fmenu__list {
  display: inline-block;
  vertical-align: top;
  min-width: 100%;
  font-size: 0;
  margin-bottom: -0.9375rem;
}
@media (max-width: 767px) {
  .fmenu__list {
    margin-bottom: -0.625rem;
  }
}
.fmenu__item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 1rem;
  line-height: 1.2222222222;
  margin-left: 4.375rem;
  margin-bottom: 0.9375rem;
}
@media (max-width: 1023px) {
  .fmenu__item {
    margin-left: 1.875rem;
    font-size: 1rem;
  }
}
@media (max-width: 767px) {
  .fmenu__item {
    display: block;
    margin-left: 0;
    margin-bottom: 0.625rem;
  }
}
.fmenu__link {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none !important;
  transition: all 0.3s;
}

/*

Common property classes

 */
.relative {
  position: relative;
}

.static {
  position: static;
}

.absolute {
  position: absolute;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.reset-margin {
  margin: 0;
}

.margin-top-auto {
  margin-top: auto;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.hidden-absolute {
  position: absolute;
  top: -624.9375rem;
  left: -624.9375rem;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

@media (max-width: 767px) {
  .text-align-left-on-mobile {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .text-align-right-on-mobile {
    text-align: right;
  }
}

@media (max-width: 767px) {
  .text-align-center-on-mobile {
    text-align: center;
  }
}

.text-ellipsis {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.vmiddle {
  vertical-align: middle;
}

.vbottom {
  vertical-align: bottom;
}

.vbaseline {
  vertical-align: baseline;
}

.clear {
  clear: both;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.nowrap {
  white-space: nowrap;
}

.wrap-normal {
  white-space: normal;
}

.no-text-transform {
  text-transform: none;
}

.uppercase {
  text-transform: uppercase;
}

.underline {
  text-decoration: underline;
}

.nounderline {
  text-decoration: none;
}

.nounderline-important {
  text-decoration: none !important;
}

.underline-hover:hover {
  text-decoration: underline;
}

.underline-hover:active {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.color-white {
  color: #fff;
}

.bg-color-white {
  background-color: #fff;
}

.color-inherit {
  color: inherit;
}

.color-inherit-important {
  color: inherit !important;
}

.color-default {
  color: #fff;
}

.color-muted {
  color: #bebebe;
}

.color-active {
  color: #888;
}

.color-active2 {
  color: #585154;
}

.color-active4 {
  color: #2491ea;
}

.color-active5 {
  color: #28b7bb;
}

.lh-default {
  line-height: 1.25;
}

.lh-default-more {
  line-height: 1.4;
}

.font-monospace {
  font-family: Consolas, Monaco, Menlo, "Courier New", Courier, monospace;
}

.font-active2 {
  font-family: "Goldman", Arial, sans-serif;
}

.font-0 {
  font-size: 0;
}

.font-xsmall {
  font-size: 0.625rem;
}

.font-small {
  font-size: 0.75rem;
}

.font-smaller {
  font-size: 0.8125rem;
}

.font-normal {
  font-size: 0.875rem;
}

.font-larger {
  font-size: 0.9375rem;
}

.font-large {
  font-size: 1rem;
}

.font-18 {
  font-size: 1.125rem;
}

.font-20 {
  font-size: 1.25rem;
}

@media (max-width: 1023px) {
  .font-normal-tablet {
    font-size: 0.875rem;
  }
}

.font-thin {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.minw140 {
  min-width: 8.75rem;
}

.minw160 {
  min-width: 10rem;
}

.mw200 {
  max-width: 12.5rem;
}

.mw250 {
  max-width: 15.625rem;
}

.mw300 {
  max-width: 18.75rem;
}

.mw350 {
  max-width: 21.875rem;
}

.mw400 {
  max-width: 25rem;
}

.mw480 {
  max-width: 30rem;
}

.mw540 {
  max-width: 33.75rem;
}

.mw560 {
  max-width: 35rem;
}

.mw640 {
  max-width: 40rem;
}

.mw720 {
  max-width: 45rem;
}

.mw1600 {
  max-width: 100rem;
}

.mw100percent {
  max-width: 100%;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.mb-0 {
  margin-bottom: 0;
}
/*# sourceMappingURL=style.css.map */
