html {
    -webkit-text-size-adjust: 100%;
    background: $color0;
    height: 100%;
}

body {
    position: relative;
    font-family: $font1;
    line-height: calc(21/16);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $color3;
    background: $color0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    display: flex;
    flex-direction: column;
    min-width: calc(320rem/$k);
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
}

html, body {
    overflow-x: hidden;
    font-size: 16px;

    @media (max-width: $screen_large) {
        font-size: calc(16vw/$i);
    }

    @media (max-width: $tablet) {
        font-size: calc(16vw/$it);
    }

    @media (max-width: $mobile) {
        font-size: calc(16vw/$im);
    }
}

*, *:before, *:after {
    box-sizing: border-box;
}


.no-animation-only-this {
    transition: none !important;
}

.no-animation {
    animation-delay: 0s!important;
    animation-duration: 0s!important;

    *:before, *:after {
        animation-delay: 0s!important;
        animation-duration: 0s!important;
    }
}

.no-transition-only-this {
    transition: none !important;
}

.no-transition {
    transition: none!important;

    *, *:before, *:after { // *:not(.swiper-wrapper):not(.swiper-pagination-bullet-timer)
        transition: none!important;
    }
}


::-moz-selection { background: #eee; color: #000; text-shadow: none; }
::selection { background: #eee; color: #000; text-shadow: none; }


.no-touchevents body {

    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
        width: calc(5rem/$k);
    }

    ::-webkit-scrollbar:horizontal {
        height: calc(5rem/$k);
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba($color0, 0.05) !important;
        border-radius: 0;
        border: none;
    }

    ::-webkit-scrollbar-track {
        border-radius: 0;
        margin: 0;
        border: none;
        background-color: rgba($color0, 0.03) !important;
    }
}



.wrap {
    position: relative;
    z-index: 0;
    float: left;
    width: 100%;
    min-height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden; // maybe need to be hidden
}

.container {
    width: 100%;
    max-width: ($cwidth+40*2)*calc(1rem/$k);
    padding-left: calc(40rem/$k);
    padding-right: calc(40rem/$k);
    margin: 0 auto;

    @media (max-width: $tablet) {
        padding-left: calc(30rem/$k);
        padding-right: calc(30rem/$k);
    }

    @media (max-width: $mobile) {
        padding-left: calc(20rem/$k);
        padding-right: calc(20rem/$k);
    }

    &.fullwidth {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}


.custom-scrollbar {

    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    ::-webkit-scrollbar:vertical {
        width: calc(5rem/$k);
    }

    ::-webkit-scrollbar:horizontal {
        height: calc(5rem/$k);
    }

    ::-webkit-scrollbar-track {
        border-radius: calc(100rem/$k);
        border: none;
        background-color: rgba($color2, 0.1) !important;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba($color2, 0.2) !important;
        border-radius: calc(100rem/$k);
        border: none;
    }

    &--dark {

        ::-webkit-scrollbar-track {
            background-color: rgba($color0, 0.05) !important;
        }

        ::-webkit-scrollbar-thumb {
            background-color: rgba($color0, 0.1) !important;
        }
    }
}